import {getDataFromFile, saveObjectToFile} from "../../../../utils/fileHandling";
import {checkAndGetEstimates} from "../../../../utils/abnDataProcessing";
import {checkAndGetQuants} from "../../../../utils/quantsProcessing";
import { ReconciliationResult } from "../../KPPage/Reconciliation/Reconciliation";
import { TrainingResult } from "../../TrainingPage/KPTraining/KPTraining";

const getKpDataFromFile = (
    currentFile: File | undefined,
    onSuccess: (data: object) => any,
    onError: (errorMessage: string) => any
) => {
    if (currentFile === undefined) {
        onError("Upload a file")
    }

    // @ts-ignore
    getDataFromFile(currentFile, (data => {
        for (const field of ['kpType', 'estimates', 'maxIndex']) {
            // @ts-ignore
            if (!data.hasOwnProperty(field)) {
                onError(`Field '${field}' not found`)
                return
            }
        }

        // @ts-ignore
        const kpType = data['kpType']
        // @ts-ignore
        const maxIndex = data['maxIndex']
        // @ts-ignore
        let estimates = data['estimates']

        if (kpType !== 'disjuncts' &&
            kpType !== 'quants' &&
            kpType !== 'conjuncts') {
            onError(`Incorrect kp type`)
            return
        }

        // if (isNaN(maxIndex)) {
        //     onError(`Incorrect max index`)
        //     return
        // }

        // @ts-ignore
        if (!Array.isArray(estimates) || estimates.length === 0) {
            onError(`Incorrect estimates`)
            return
        }

        const estimatesType = Array.isArray(estimates[0]) ? 'interval' : 'scalar'
        const estimatesResult = checkAndGetEstimates(estimates, kpType, maxIndex, estimatesType)
        if (estimatesResult.errorMessage !== '') {
            onError(`Incorrect estimates`)
            return
        }

        onSuccess({
            kpType: kpType,
            estimates: estimates,
            maxIndex: maxIndex
        })
    }), (errorMessage => {
        onError(errorMessage)
    }))
}

const saveReconciliationKpResult = (result: ReconciliationResult) => {
    saveObjectToFile(result, result.kpType)
}

const saveQuantKpResult = (result: TrainingResult) => {
    const data = {
        // @ts-ignore
        inputQuants: result.inputQuants,
        // @ts-ignore
        quantsCount: result.quantsCount,
        // @ts-ignore
        maxIndex: result.maxIndex,
        // @ts-ignore
        outputQuants: result.outputEstimates,
    }
    saveObjectToFile(data, 'conjuncts')
}

const getKpAndQuantsDataFromFile = (
    currentFile: File | undefined,
    onSuccess: (data: object) => any,
    onError: (errorMessage: string) => any
) => {
    if (currentFile === undefined) {
        // onError("Upload a file")
        onError("Загрузите файл")
    }

    // @ts-ignore
    getDataFromFile(currentFile, (data => {
        for (const field of ['maxIndex', 'quants', 'estimatesType']) {
            // @ts-ignore
            if (!data.hasOwnProperty(field)) {
                // onError(`Field '${field}' not found`)
                onError(`Файл '${field}' не найден`)
                return
            }
        }

        // @ts-ignore
        const maxIndex = data['maxIndex']
        // @ts-ignore
        let quants = data['quants']
        // @ts-ignore
        const estimatesType = data['estimatesType']

        if (isNaN(maxIndex)) {
            // onError(`Incorrect max index`)
            onError(`Некорректные индексы атомов`)
            return
        }

        // @ts-ignore
        if (!Array.isArray(quants)) {
            // onError(`Incorrect max index`)
            onError(`Некорректные индексы атомов`)
            return
        }

        // @ts-ignore
        if (quants.length === 0) {
            // onError(`Incorrect max index`)
            onError(`Некорректные индексы атомов`)
            return
        }
        /*
        if (!Array.isArray(quants) || quants.length === 0) {
            onError(`Incorrect estimates`)
            return
        } */

        const estimatesResult = checkAndGetQuants(quants, estimatesType)
        if (estimatesResult.errorMessage !== '') {
            // onError(`Incorrect max index`)
            onError(`Некорректные индексы атомов`)
            return
        }


        onSuccess({
            quants: estimatesResult,
            quantsCount: quants.length,
            estimatesType: estimatesType,
            maxIndex: maxIndex
        })
    }), (errorMessage => {
        onError(errorMessage)
    }))
}

export { getKpDataFromFile, saveReconciliationKpResult, getKpAndQuantsDataFromFile, saveQuantKpResult}
