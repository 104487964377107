const historyTypes = {
    kpReconciliation: "kpReconciliation",
    parentGraphGlobalInfer: "parentGraphGlobalInfer",
    acyclicalityCheck: "acyclicalityCheck"
}

const TIME_OPTIONS = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    timezone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};


const getHistoryData = (historyType: string) => {
    const data = localStorage.getItem(historyType)
    return data ? JSON.parse(data) : [];
}

const addHistoryItem = (historyType: string, newItem: object) => {
    let date = new Date();
    // @ts-ignore
    const dateString = date.toLocaleString("ru", TIME_OPTIONS)
    const previousHistory = getHistoryData(historyType)
    const newHistoryData = [
        ...previousHistory,
        {
            date: dateString,
            ...newItem
        }
    ]
    localStorage.setItem(historyType, JSON.stringify(newHistoryData));
}


// Returns history data with deleted element
const deleteHistoryItem = (historyType: string, itemIndex: number) => {
    const newHistory = getHistoryData(historyType).filter((item: string, index: number) => index !== itemIndex)
    localStorage.setItem(historyType, JSON.stringify(newHistory))
    return newHistory
}

export {historyTypes, getHistoryData, deleteHistoryItem, addHistoryItem}
