import React, {useEffect} from 'react';
import {Box, TextField, TextFieldProps, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import {isNumeric} from "../../../../utils/numberProcessing";


const TextFieldStyling = styled(TextField)<TextFieldProps & { isLeft?: boolean }>(({theme, isLeft}) => {
    const borderWidth = (defaultWidth: number) => isLeft ? `${defaultWidth}px 0px ${defaultWidth}px ${defaultWidth}px` : `${defaultWidth}px ${defaultWidth}px ${defaultWidth}px 0px`
    const borderRadius = (defaultRadius: number) => isLeft ? `${defaultRadius}px 0 0 ${defaultRadius}px` : `0 ${defaultRadius}px ${defaultRadius}px 0`

    return {
        '& .MuiOutlinedInput-root': {
            height: 35,
            width: 60,
            borderRadius: borderRadius(5),
            fontSize: 12,
            '& fieldset': {
                borderWidth: borderWidth(1),
                borderColor: 'lightgray',
            },
            '&:hover fieldset': {
                borderWidth: borderWidth(1),
                borderColor: 'gray',
                boxShadow: '0 0 3px rgba(0,0,0,0.5)',
            },
            '&.Mui-focused fieldset': {
                borderWidth: borderWidth(2),
                borderColor: theme.palette.primary.main,
                boxShadow: '0 0 3px rgba(0,0,0,0.5)',
            },
        },
    }
})

interface IOneEstimateEntering {
    isLeft?: boolean
    onChange: (newValue: any) => any
    value: string
    isClearInput?: boolean
}

const OneEstimateEntering = ({isLeft, onChange, isClearInput}: IOneEstimateEntering) => {
    const [value, setValue] = React.useState('')

    useEffect(() => {
        if (isClearInput) {
            setValue('')
        }
    }, [isClearInput]);

    // @ts-ignore
    const onChangeValue = (event) => {
        const newValue = event.target.value
        if (
            isNumeric(newValue) ||
            newValue.match(/^[+|-]$/) ||
            newValue.match(/^(\+|-)?\d+\.$/) ||
            newValue === ""
        ) {
            setValue(event.target.value)
            onChange(event.target.value)
        }
    }

    return (
        <TextFieldStyling id="outlined-basic"
                          isLeft={isLeft}
                          variant="outlined"
                          onChange={onChangeValue}
                          value={value}
        />
    )
}

interface IEstimatesText {
    children: React.ReactNode
    sx?: any
}

const EstimatesText = ({children, sx}: IEstimatesText) => (
    <Box sx={{backgroundColor: '#424242', px: 1, ...sx}} display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body2" sx={{color: 'white'}}>
            {children}
        </Typography>
    </Box>
)


export {OneEstimateEntering, EstimatesText};
