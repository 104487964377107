import {KpType} from "../components/common/entering/MetaKPDataEntering";

function maxIndexToBinary(maxIndex: number[]) {
    if (maxIndex.length === 0) {
        return '0'
    }

    const maxAtomIndex = Math.max(...maxIndex)
    const arrayWithBits = Array(maxAtomIndex + 1).fill("0")
    for (const atomIndex of maxIndex) {
        arrayWithBits[atomIndex] = "1"
    }
    return arrayWithBits.reverse().join('')
}

function binaryToMaxIndex(binary: string): number[] {
    const maxIndex: number[] = []
    binary.split('').reverse().forEach((bit, index) => {
        if (bit == "1") {
            maxIndex.push(index)
        }
    })
    return maxIndex
}

function numberToBinary(number: number) {
    return (number).toString(2);
}

function countUnitBits(number: number) {
    const numberAsString = (number >>> 0).toString(2)
    let count = 0;
    for (let i = 0; i < numberAsString.length; i++) {
        if (numberAsString[i] === "1") {
            count++
        }
    }
    return count
}

function bitsToAtomsString(bits: string, type: KpType, maxIndex?: number[]) {
    const bitsCount = bits.length
    const maxIndexBits = maxIndex && maxIndex.length !== 0 ? maxIndexToBinary(maxIndex) : '0'
    let text: string[] | string = []
    if (type !== 'quants') {
        for (let i = bitsCount - 1; i >= 0; i--) {
            let currentBit = bits.charAt(i)
            if (currentBit === "1") {
                text.unshift(`x_{${bitsCount - i - 1}}`)
            }
        }
        if (text === []) {
            return "ε"
        }
        text = text.join(type === 'conjuncts' ? '' : ' \\vee ')
    } else {
        for (let i = maxIndexBits.length - 1; i >= 0; i--) {
            if (maxIndexBits[i] === "1") {
                const bitIndex = i - maxIndexBits.length + bits.length
                text.unshift(bits[bitIndex] && bits[bitIndex] === "1" ? `x_{${bitsCount - bitIndex - 1}}` : `\\overline{x \\raisebox{2mm}{}}_{${bitsCount - bitIndex - 1}}`)
            }
        }
        text = text.join('')
    }
    return text
}

const isNumericRegex = /^-?[0-9]+(?:\.[0-9]+)?$/
const isNumeric = (num: string) => isNumericRegex.test(num + '');

export {countUnitBits, bitsToAtomsString, isNumeric, isNumericRegex, numberToBinary, maxIndexToBinary, binaryToMaxIndex}
