import React, {useEffect} from 'react';
import {Grid} from "@mui/material";
import StyledFormControl from "./StyledFormControl";
import MaxIndexEntering from "./MaxIndexEntering";
import {getNewEstimates, KPEntering} from "./KPEntering";

type EvidenceType = "stochastic" | "imprecise" | "deterministic"
const evidenceTypes = ["stochastic", "imprecise", "deterministic"]
type DeterministicDataType = {
    positiveIndex: number[]
    negativeIndex?: number[]
}

type EvidenceInputDataType = {
    evidenceType: EvidenceType,
    data: {
        maxIndex: number[]
        estimates: string[] | string[][] | undefined
    } | DeterministicDataType
}

type EvidenceDataType = {
    evidenceType: EvidenceType,
    data: {
        maxIndex: number[]
        estimates: string[] | string[][]
    } | DeterministicDataType
}

interface IEvidenceEntering {
    onEvidenceDataChange: (newEvidenceData: EvidenceInputDataType) => any
}

const INITIAL_MAX_INDEX = [0]


const EvidenceEntering = ({onEvidenceDataChange}: IEvidenceEntering) => {
    const [evidenceType, setEvidenceType] = React.useState<EvidenceType>('stochastic');
    const [maxIndex, setMaxIndex] = React.useState<number[]>(INITIAL_MAX_INDEX);
    const [estimates, setEstimates] = React.useState<string[] | string[][] | undefined>(getNewEstimates("conjuncts", INITIAL_MAX_INDEX))
    const [deterministicData, setDeterministicData] = React.useState<DeterministicDataType | undefined>(undefined)
    const [isClearInput, setIsClearInput] = React.useState<boolean>(true)

    const onEvidenceTypeChange = (newValue: EvidenceType) => {
        setEvidenceType(newValue)
        if (newValue !== 'deterministic') {
            setMaxIndex(INITIAL_MAX_INDEX)
            setEstimates(getNewEstimates("conjuncts", INITIAL_MAX_INDEX))
            setDeterministicData(undefined)
        } else {
            setMaxIndex([])
            setEstimates(undefined)
            setDeterministicData({positiveIndex: [0], negativeIndex: []})
        }
    }

    const onEstimatesChange = (newEstimates: any) => {
        setEstimates(newEstimates)
        setIsClearInput(false)
    }

    useEffect(() => {
        setIsClearInput(true)
    }, [maxIndex, evidenceType])



    useEffect(() => {
        const data = evidenceType === 'deterministic' ? deterministicData : {maxIndex, estimates}
        onEvidenceDataChange({
            evidenceType: evidenceType,
            // @ts-ignore
            data
        })
    }, [evidenceType, maxIndex, estimates, deterministicData])

    return (
        <Grid container direction="column"  spacing={4} width={'100%'}>
            <Grid item container direction="row" justifyContent="flex-start" width={'100%'}>
                <Grid item sx={{mr: 2}}>
                    <StyledFormControl
                        // label={'Evidence type'}
                        label={'Тип свидетельства'}
                        // @ts-ignore
                                       values={evidenceTypes}
                                       onValueChange={onEvidenceTypeChange}
                                       value={evidenceType}
                    />
                </Grid>
                {
                    evidenceType !== "deterministic" ?
                        <Grid item sx={{mx: 2}}>
                            <MaxIndexEntering
                                maxIndexValue={maxIndex}
                                onMaxIndexChange={setMaxIndex}
                                maxAtoms={6}
                                label="Max evidence index"
                            />
                        </Grid>
                        :
                        <>
                            <Grid item sx={{mx: 2}}>
                                {/*// @ts-ignore*/}
                                <MaxIndexEntering maxIndexValue={deterministicData.positiveIndex} onMaxIndexChange={(newValue) => {
                                    setDeterministicData({
                                        ...deterministicData,
                                        // @ts-ignore
                                        positiveIndex: newValue
                                    })
                                }} maxAtoms={6} label="Positive conjunct index" minAtoms={0}/>
                            </Grid>
                            <Grid item sx={{mx: 2}}>
                                {/*// @ts-ignore*/}
                                <MaxIndexEntering maxIndexValue={deterministicData.negativeIndex} onMaxIndexChange={(newValue) => {
                                    // @ts-ignore
                                    setDeterministicData({
                                        ...deterministicData,
                                        negativeIndex: newValue
                                    })
                                }} maxAtoms={6} label="Negative conjunct index" minAtoms={0}/>
                            </Grid>
                        </>
                }
            </Grid>
            {
                evidenceType !== "deterministic" &&
                <Grid item>
                    <KPEntering maxIndex={maxIndex} kpType="conjuncts"
                                type={evidenceType === 'stochastic' ? 'scalar' : 'interval'}
                                onEstimatesChange={onEstimatesChange} estimates={estimates}
                                isClearInput={isClearInput}
                    />
                </Grid>
            }
        </Grid>
    );
};

export {EvidenceEntering}
export type {EvidenceInputDataType, EvidenceType, EvidenceDataType}
