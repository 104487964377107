import React from 'react';
import {Box} from "@mui/material";
import {EstimatesType, KpType, MetaKPDataEntering} from "../../../common/entering/MetaKPDataEntering";
import {KPEntering} from "../../../common/entering/KPEntering";

interface ICustomDataEntering {
    kpType: KpType
    estimatesType: EstimatesType
    maxIndex: number[]
    estimates: string[] | string[][] | undefined
    isClearInput?: boolean
    onMaxIndexChange: (maxIndex: number[]) => any
    onChangeKPType: (newValue: KpType) => any
    onChangeEstimatesType: (newValue: EstimatesType) => any
    onEstimatesChange: (newEstimates: string[] | string[][]) => any
}

const CustomDataEntering = ({
                                kpType,
                                estimates,
                                estimatesType,
                                maxIndex,
                                onEstimatesChange,
                                onChangeEstimatesType,
                                onMaxIndexChange,
                                onChangeKPType,
                                isClearInput
                            }: ICustomDataEntering) => {
    return (
        <Box>
            <MetaKPDataEntering kpTypeValue={kpType}
                                estimateTypeValue={estimatesType}
                                maxIndexValue={maxIndex}
                                onMaxIndexChange={onMaxIndexChange}
                                onChangeKPType={onChangeKPType}
                                onChangeEstimatesType={onChangeEstimatesType}
                                needChooseKPType
            />

            <Box sx={{my: 5}}>
                <KPEntering maxIndex={maxIndex}
                            onEstimatesChange={onEstimatesChange}
                            estimates={estimates}
                            isClearInput={isClearInput}
                    // @ts-ignore
                            type={estimatesType.toString().toLocaleLowerCase()}
                    // @ts-ignore
                            kpType={kpType.toLowerCase()}/>
            </Box>
        </Box>
    );
};

export default CustomDataEntering;
