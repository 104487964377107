import {Accordion, AccordionSummary, Box, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import KpEntering from "./KpEntering";

interface IAllKPEntering {
    kpsCount: number
    onKpsChange: (index: number, newEstimates: string[] | string[][], maxIndex: number[]) => any
}

const AllKPEntering = ({onKpsChange, kpsCount}: IAllKPEntering) => {
    let indices = [];
    for (let i = 0; i < kpsCount; i++) {
        indices.push(i);
    }

    return (
        <Box>
            {
                indices.map(i => {
                    return (
                        <Accordion sx={{my: 1}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                {/*<Typography>*/}
                                {/*    Knowledge pattern {i + 1}*/}
                                {/*</Typography>*/}
                                <Typography>
                                    Модель фрагмента знаний №{i + 1}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <KpEntering
                                    onDataChange={(newEstimates, maxIndex) => (
                                        onKpsChange(i, newEstimates, maxIndex)
                                    )}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            }
        </Box>
    )
}

export default AllKPEntering
