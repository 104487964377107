import React from 'react';
import {Grid, Paper, Typography} from "@mui/material";
import {EstimatesText} from "./common";
// @ts-ignore
import {InlineMath} from 'react-katex';


interface IQuantEstimates {
    text: string
    sx?: any
    leftValue: number
    rightValue?: number
}

interface IInputEstimateMock {
    number: number
    isLeft?: boolean
}

const InputEstimateMock = ({number, isLeft}: IInputEstimateMock) => {
    return (
        <Paper variant="outlined" sx={{px: 1.5, height: 35, borderRadius: isLeft ? '3px 0px 0px 3px' : '0px 3px 3px 0px'}}>
            <Grid container  sx={{
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center'
            }} >
                <Typography textAlign={'center'}>
                    {number}
                </Typography>
            </Grid>
        </Paper>
    )
}

const QuantEstimates = ({text, sx, leftValue, rightValue}: IQuantEstimates) => {
    return (
        <div>
            <Grid container direction="row" sx={{...sx, width: 'max-content'}}>

                <InputEstimateMock number={leftValue} isLeft />
                <EstimatesText><InlineMath math={text.toString()}/></EstimatesText>
                {
                    // @ts-ignore
                    rightValue &&  <InputEstimateMock number={rightValue} />
                }

            </Grid>
        </div>
    );
};

export default QuantEstimates;
