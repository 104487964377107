import React, {useEffect, useState} from 'react';
import {DataProcessingPage, HowAddDataType} from "../../../common/entering/DataProcessingPage/DataProcessingPage";
import {addHistoryItem, deleteHistoryItem, getHistoryData, historyTypes} from "../../../history/historyProcessing";
import {getKpsIndicesFile, saveAcyclicalityResult} from "./fileHandling";
import {HistoryDataType as HistoryDataPlateType} from "../../../history/HistoryFeed";
import IndicesEntering from "./IndicesEntering";
import FileStructure from './FileStructure';
import AcyclicalityHistoryDialogContent from "./AcyclicalityHistoryDialogContent";
import {AcyclicalityResult as AcyclicalityResultComponent} from './AcyclicalityResult';
import {paths} from "../../../../config";
import {getDataFromServer} from "../../../../utils/httpUtils";
import {binaryToMaxIndex, maxIndexToBinary} from "../../../../utils/numberProcessing";

const HISTORY_TYPE = historyTypes.acyclicalityCheck

interface AcyclicalityResult {
    isAcyclic: boolean
    kpsIndices: number[][]
}

interface AcyclicalityRequest {
    kpsIndices: number[][]
}

interface AcyclicalityResponse extends AcyclicalityResult {
    errorMessage: string
}

interface HistoryDataItem extends AcyclicalityResponse {
    date: string
}

type HistoryData = HistoryDataItem[] | never[]


const setAcyclicalityData = (
    acyclicalityData: AcyclicalityRequest,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: AcyclicalityResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    getDataFromServer(acyclicalityData, paths.checkAcyclicality, response => {
        if (response.errorMessage !== '') {
            onGetErrorMessage(response.errorMessage)
            onGetResultData(undefined)
        } else {
            onGetResultData({
                // kpsIndices: response.kpsIndices.map((i: string) => binaryToMaxIndex(i)),
                kpsIndices: response.kpsIndices,
                isAcyclic: response.isAcyclic,
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        }
    }, error => onGetErrorMessage(error.toString()))
}


const checkAcyclicality = (
    howAddEstimate: HowAddDataType,
    file: File | undefined,
    kpsIndices: number[][],
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: AcyclicalityResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    if (howAddEstimate === "upload") {
        getKpsIndicesFile(file, result => {
            setAcyclicalityData(
                result,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }, errorMessage => {
            onGetResultData(undefined)
            if (errorMessage.startsWith("One knowledge pattern")) {
                onGetErrorMessage("Один ФЗ включен в другой")
            } else {
                onGetErrorMessage(errorMessage)
            }
        })
    } else {  // howAddEstimate === "custom"
        if (kpsIndices.length === 0) {
            // onGetErrorMessage("No indices was entered")
            onGetErrorMessage("Количество моделей фрагментов знаний должно быть больше 0")
            onGetResultData(undefined)
            return
        }

        for (const kpsIndex of kpsIndices) {
            if (kpsIndex.length == 0) {
                // onGetErrorMessage("Please, enter all indices")
                onGetErrorMessage("Пожалуйста, введите индексы атомов для всех моделей фрагментов знаний")
                onGetResultData(undefined)
                return
            }
        }

        // const newIndices = kpsIndices.map(i => maxIndexToBinary(i))
        setAcyclicalityData(
            {kpsIndices: kpsIndices},
            onGetErrorMessage,
            onGetResultData,
            onGetNewHistoryItem
        )
    }
}


const AcyclicalityCheck = () => {
    const [howAddIndices, setHowAddIndices] = React.useState<HowAddDataType>('custom');
    const [file, setFile] = useState<File | undefined>(undefined);
    const [historyData, setHistoryData] = React.useState<HistoryData>(getHistoryData(HISTORY_TYPE));
    const [kpsIndices, setKpsIndices] = React.useState<(number[])[]>([])
    const [errorMessage, setErrorMessage] = useState('')
    const [resultData, setResultData] = React.useState<AcyclicalityResult | undefined>(undefined);
    // @ts-ignore
    const [historyPlateData, setHistoryPlateData] = React.useState<HistoryDataPlateType>([]);

    const onDeleteHistoryItem = (i: number) => {
        const newHistory = deleteHistoryItem(HISTORY_TYPE, i)
        setHistoryData(newHistory)
    }

    useEffect(() => {
        const content = historyData.map(historyItem => (
            {
                date: historyItem.date,
                // title: "Acyclicality check",
                title: "Проверка ацикличности",
                hasSuccess: historyItem.isAcyclic
            }
        ))
        setHistoryPlateData(
            {
                // successMessage: 'Acyclic',
                successMessage: 'Ациклична',
                // failureMessage: 'Not acyclic',
                failureMessage: 'Не ациклична',
                content
            }
        )
    }, [historyData])

    const getDialogHistoryItem = (historyIndex: number) => {
        const historyItem = historyData[historyIndex]
        return (
            <AcyclicalityHistoryDialogContent
                // @ts-ignore
                isAcyclic={historyItem['isAcyclic']}
                // @ts-ignore
                kpsIndices={historyItem['kpsIndices']}
            />
        )
    }

    return (
        <DataProcessingPage
            howAddData={howAddIndices}
            // @ts-ignore
            onAddDataChange={setHowAddIndices}
            chooseDataEnteringTypeLabel="Как вводить данные?"
            // chooseDataEnteringTypeLabel="How to add indices"
            enterCustomDataLabel="Вручную"
            // enterCustomDataLabel="Enter indices"
            customDataEntering={<IndicesEntering indices={kpsIndices} onIndicesChange={setKpsIndices} />}
            fileStructure={<FileStructure/>}
            onFileUpload={setFile}
            postUrl={paths.checkAcyclicality}
            onGetResultButtonClick={() => checkAcyclicality(
                howAddIndices,
                file,
                // @ts-ignore
                kpsIndices,
                setErrorMessage,
                setResultData,
                (newItem: any) => {
                    addHistoryItem(HISTORY_TYPE, newItem)
                    setHistoryData(getHistoryData(HISTORY_TYPE))
                }
            )}
            // getResultButtonLabel="Check acyclicality"
            getResultButtonLabel="Проверить ацикличность"
            errorMessage={errorMessage}
            resultComponent={
                resultData
                    ? <AcyclicalityResultComponent isAcyclic={resultData['isAcyclic']} kpsIndices={resultData['kpsIndices']} />
                    : null
            }
            historyData={historyPlateData}
            onDeleteItem={onDeleteHistoryItem}
            getResultItem={getDialogHistoryItem}
            // @ts-ignore
            onSaveButtonClick={() => saveAcyclicalityResult(resultData)}
        />
    )
}

export {AcyclicalityCheck}
export type {AcyclicalityRequest, AcyclicalityResult}
