import React from 'react';
import {Dialog, Grid, Typography} from "@mui/material";
import HistoryPlate from "./HistoryPlate";

type HistoryDataType = {
    successMessage: string
    failureMessage: string
    content: {
        date: string
        title: string
        hasSuccess: boolean
    }[] | never[]
}

interface IHistoryFeed {
    historyData: HistoryDataType
    onDeleteItem: (i: number) => any
    getResultItem: (index: number) => React.ReactNode
}

const HistoryFeed = ({historyData, onDeleteItem, getResultItem}: IHistoryFeed) => {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState<number | undefined>(undefined)

    const handleClickOpenDialog = (index: number) => {
        setSelectedItemIndex(index);
    }

    const handleCloseDialog = () => {
        setSelectedItemIndex(undefined);
    }

    return (
        <Grid container spacing={1.5} direction="column">
            {
                historyData.content && historyData.content.length !== 0 && (
                    <Grid item>
                        {/*<Typography variant="h4" textAlign="center">*/}
                        {/*    History*/}
                        {/*</Typography>*/}
                        <Typography variant="h4" textAlign="center">
                            История
                        </Typography>
                    </Grid>
                )
            }
            {
                historyData.content && historyData.content.slice(0).reverse().map((item: any, i: number) => {
                    return (
                        <Grid item key={item['date']}>
                            <HistoryPlate
                                date={item['date']}
                                title={item['title']}
                                plateNumber={i + 1}
                                onDeletePlate={() => onDeleteItem(historyData.content.length - i - 1)}
                                onClickPlate={() => handleClickOpenDialog(historyData.content.length - i - 1)}
                                message={item['hasSuccess'] ? historyData['successMessage'] : historyData['failureMessage']}
                                hasSuccess={item['hasSuccess']}
                            />
                        </Grid>
                    )
                })
            }

            <Dialog onClose={handleCloseDialog} open={selectedItemIndex !== undefined} maxWidth='lg'>
                {
                    selectedItemIndex !== undefined && getResultItem(selectedItemIndex)
                }
            </Dialog>
        </Grid>
    )
}

export {HistoryFeed};
export type {HistoryDataType};

