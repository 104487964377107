import React from 'react';
import TextField from "@mui/material/TextField";
import { countUnitBits } from "../../../utils/numberProcessing";

interface IMaxIndexEntering {
    maxIndexValue: number | undefined
    onMaxIndexChange: (newNumber: number | undefined) => any
    maxAtoms: number
    minAtoms?: number
    label?: string
    width?: number
}

const MaxIndexEntering = ({ width, minAtoms, label, maxIndexValue, onMaxIndexChange, maxAtoms }: IMaxIndexEntering) => (
    <TextField
        id="outlined-number"
        // label={label ? label : "Quants count"}
        label={label ? label : "Количество квантов"}
        type="number"
        value={maxIndexValue}
        // @ts-ignore
        onChange={(event) => {
            const value = event.target.value

            const newNumber = value === '' ? undefined : Number(value)
            if (newNumber === undefined || (newNumber >= (minAtoms ? minAtoms : 1) && countUnitBits(newNumber) <= maxAtoms)) {
                onMaxIndexChange(newNumber)
            }
        }}
        onKeyDown={evt => {
            if (evt.key.match(/[.,+-]/)) {
                evt.preventDefault()
            }
        }}
        sx={{ width: width ? width : 175 }}
        InputLabelProps={{
            shrink: true,
        }}
    />
)

export default MaxIndexEntering;
