import React, {useEffect} from 'react';
import {Box, Grid, BoxProps} from "@mui/material";
import {styled} from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ClickableBox from "../DoubleClick";

type TripleCheckboxValueType = boolean | undefined

const VanishingCircle = styled(Box)<BoxProps>(({theme}) => ({
    transition: theme.transitions.create(['width', 'height', 'opacity'], {duration: 300}),
}))

interface ITripleCheckbox {
    onCheckboxChange: (newValue: TripleCheckboxValueType) => void
    isClearInput?: boolean
}

const valueArray = [undefined, true, false]
const size = 35

const TripleCheckbox = ({onCheckboxChange, isClearInput}: ITripleCheckbox) => {
    const [valueArrayIndex, setValueArrayIndex] = React.useState<number>(0);
    const [isCircleVisible, setIsCircleVisible] = React.useState<boolean>(true);
    const [isCircleBig, setIsCircleBig] = React.useState<boolean>(false);

    useEffect(() => {
        if (isClearInput) {
            setValueArrayIndex(0)
        }
    }, [isClearInput])

    const increaseValueArrayIndex = (valueToIncrease: number) => {
        const newValue = (valueArrayIndex + valueToIncrease) % valueArray.length
        setValueArrayIndex(newValue)
        onCheckboxChange(valueArray[newValue])
    }

    const showIcon = () => {
        setIsCircleVisible(false)
        setIsCircleBig(true)
        setTimeout(() => setIsCircleBig(false), 300)
        setTimeout(() => setIsCircleVisible(true), 500)
    }

    let icon
    let boxStyle
    let backgroundCircleColor
    switch (valueArray[valueArrayIndex]) {
        case undefined:
            icon = null
            boxStyle = {
                border: 2,
                borderColor: "#757575",
                backgroundColor: "white"
            }
            backgroundCircleColor = "#757575"
            break
        case true:
            icon = <CheckIcon sx={{
                color: 'white',
                fontSize: size,
                ml: -0.2,
                mt: 0.3
            }}/>
            boxStyle = {backgroundColor: "#81c784"}
            backgroundCircleColor = "#81c784"
            break
        case false:
            icon = <CloseIcon sx={{
                fontSize: size,
                color: 'white',
                ml: -0.2,
                mt: 0.4
            }}/>
            boxStyle = {backgroundColor: "#e53935"}
            backgroundCircleColor = "#e53935"
            break
    }

    return (
        <ClickableBox
            onClick={() => {
                increaseValueArrayIndex(1)
                showIcon()
            }}
            onDoubleClick={() => {
                increaseValueArrayIndex(2)
                showIcon()
            }}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    borderRadius: 1.5,
                    width: size,
                    height: size,
                    ...boxStyle
                }}
            >
                <Grid style={{position: "absolute", zIndex: -100}}>
                    <VanishingCircle sx={{
                        width: isCircleBig ? 80 : 0,
                        height: isCircleBig ? 80 : 0,
                        opacity: isCircleVisible ? 1 : 0,
                        borderRadius: '50%',
                        backgroundColor: backgroundCircleColor
                    }}/>
                </Grid>
                <Grid>
                    {icon}
                </Grid>
            </Grid>
        </ClickableBox>
    );
}

export default TripleCheckbox;