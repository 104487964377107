import React from 'react';
import {Grid} from "@mui/material";
import StyledFormControl from "./StyledFormControl";
import MaxIndexEntering from "./MaxIndexEntering";

type KpType = 'conjuncts' | 'quants' | 'disjuncts'
type EstimatesType = 'interval' | 'scalar'
const kpTypes: KpType[] = ['conjuncts', 'quants', 'disjuncts']
const estimatesTypes: EstimatesType[] = ['interval', 'scalar']

interface IMetaKPDataEntering {
    maxIndexValue: number[]
    kpTypeValue: KpType
    estimateTypeValue: EstimatesType
    onMaxIndexChange: (maxIndex: number[]) => any
    onChangeKPType?: (newValue: KpType) => any
    onChangeEstimatesType: (newValue: EstimatesType) => any
    needChooseKPType?: boolean
}

const MetaKPDataEntering = ({
                                onMaxIndexChange,
                                kpTypeValue,
                                maxIndexValue,
                                estimateTypeValue,
                                onChangeKPType,
                                onChangeEstimatesType,
                                needChooseKPType
                            }: IMetaKPDataEntering) => {

    return (
        <Grid container direction="row" spacing={4}>
            {
                needChooseKPType && onChangeKPType &&
                <Grid item>
                    <StyledFormControl
                        // label={'Knowledge pattern type'}
                        label={'Тип модели фрагмента знаний'}
                                       values={kpTypes}
                                       value={kpTypeValue}
                                       onValueChange={onChangeKPType}/>
                </Grid>
            }


            <Grid item>
                <StyledFormControl
                    // label={'Estimates type'}
                    label={'Тип оценок'}
                                   values={estimatesTypes}
                                   value={estimateTypeValue}
                                   onValueChange={onChangeEstimatesType}/>
            </Grid>

            <Grid item>
                <MaxIndexEntering maxIndexValue={maxIndexValue} onMaxIndexChange={onMaxIndexChange} maxAtoms={6}/>
            </Grid>
        </Grid>
    )
}

export {MetaKPDataEntering, kpTypes, estimatesTypes}
export type {KpType, EstimatesType}
