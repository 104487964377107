import React from 'react';
import {Box, Button, Grid, Typography, Paper, ToggleButtonProps, ButtonProps} from "@mui/material";
import {styled} from "@mui/material/styles";

interface IHistoryPlate {
    plateNumber: number
    title: string
    date: string
    onDeletePlate: () => any
    onClickPlate: () => any
    message: string
    hasSuccess?: boolean
}

interface ITextComponent {
    text: string
}

const TextComponent = ({text}: ITextComponent) => (
    <Box sx={{backgroundColor: '#ad1457', color: '#eeeeee', px: 1.5, py: 0.2, borderRadius: 2}}>
        <Typography>
            {text}
        </Typography>
    </Box>
)

interface IMovablePaper {
    sx: any,
    elevation: number
    onClick: () => any
}

const MovablePaper = styled(Paper)<ToggleButtonProps | IMovablePaper>(({theme}) => ({
    transition: theme.transitions.create(['all'], {duration: theme.transitions.duration.standard}),
    bottom: '0px',
    position: 'relative',
    '&.MuiPaper-root': {
        '&:hover, &.Mui-focusVisible': {
            bottom: '7px',
        },
    },
}))

interface IDeleteButton {
    sx: any,
    onClick: () => any
}

const DeleteButton = styled(Button)<ButtonProps | IDeleteButton>(({theme}) => ({
    '&.MuiButton-root': {
        '&:hover, &.Mui-focusVisible': {
            color: '#bdbdbd',
            backgroundColor: '#e0e0e0'
        },
    },
}))


const HistoryPlate = ({
                          plateNumber,
                          title,
                          onDeletePlate,
                          onClickPlate,
                          message,
                          hasSuccess,
                          date
                      }: IHistoryPlate) => {
    // @ts-ignore
    const onButtonClicked = (event) => {
        event.stopPropagation()
        onDeletePlate()
    }

    const messageColor = hasSuccess ? '#81c784' : '#e57373'
    return (
        <MovablePaper sx={{py: 1.2, borderRadius: 3, px: 3}} elevation={4} onClick={onClickPlate}>
            <Grid container direction="row"
                  alignItems="center" justifyContent="space-between">
                <Grid item container direction="row" width={'max-content'} spacing={2} alignItems="center">
                    <Grid item>
                        {plateNumber}
                    </Grid>

                    <Grid item>
                        <TextComponent text={title}/>
                    </Grid>

                    <Grid item>
                        <TextComponent text={date}/>
                    </Grid>
                </Grid>

                <Grid item container direction="row" alignItems="center" spacing={2} width={'max-content'}>
                    <Grid item>
                        <Typography color={messageColor}>
                            {message}
                        </Typography>
                    </Grid>

                    <Grid item container direction="row" alignItems="center" justifyContent="center" width={39}>
                        <DeleteButton sx={{
                            backgroundColor: '#bdbdbd', minWidth: 1, minHeight: 1, height: 22, width: 22,
                            alignItems: "center", justifyContent: "center", color: 'white'
                        }}
                                      onClick={onButtonClicked}>
                            <Typography fontSize={25}>
                                ×
                            </Typography>
                        </DeleteButton>
                    </Grid>
                </Grid>
            </Grid>
        </MovablePaper>
    );
};

export default HistoryPlate;
