import React from 'react';
import {Box, Grid} from "@mui/material";
import TrainingDataEntering from "./TrainingDataEntering";
import QuantCountEntering from "../../../common/entering/QuantsCountEntering";
import MaxIndexEntering from "../../../common/entering/MaxIndexEntering";
import StyledFormControl from "../../../common/entering/StyledFormControl";
import {EstimatesType, estimatesTypes} from "../../../common/entering/MetaKPDataEntering";

interface ICustomDataEntering {
    maxIndex: number[]
    quantCount: number | undefined
    onMaxIndexChange: (number: number[]) => any
    onQuantCountChange: (number: number | undefined) => any
    onQuantChange: (quantIndex: number, atomIndex: number, newValue: boolean | undefined) => void
    isClearInput?: boolean
    estimateType: EstimatesType
    onChangeEstimatesType: (newValue: EstimatesType) => any
}

const CustomDataEntering = ({
                                maxIndex,
                                onMaxIndexChange,
                                quantCount,
                                onQuantCountChange,
                                isClearInput,
                                onQuantChange,
                                estimateType,
                                onChangeEstimatesType
                            }: ICustomDataEntering) => {
    return (
        <Box mb={3}>
            <Grid container direction="row" spacing={4} mb={2}>
                <Grid item>
                    <StyledFormControl
                        // label={'Estimates type'}
                        label={'Тип оценок'}
                                       values={estimatesTypes}
                                       value={estimateType}
                                       onValueChange={onChangeEstimatesType}/>
                </Grid>

                <Grid item>
                    <QuantCountEntering maxIndexValue={quantCount} onMaxIndexChange={onQuantCountChange} maxAtoms={20}/>
                </Grid>

                <Grid item>
                    <MaxIndexEntering maxIndexValue={maxIndex} onMaxIndexChange={onMaxIndexChange} maxAtoms={6}/>
                </Grid>
            </Grid>
            <TrainingDataEntering
                onQuantChange={onQuantChange}
                maxIndex={maxIndex}
                quantsCount={quantCount}
                isClearInput={isClearInput}
            />
        </Box>
    );
};

export default CustomDataEntering;
