import React from 'react';
import {Typography} from "@mui/material";

const keyColor = "#ac0800"
const valueColor = "#212121"
const commentColor = "#999999"

interface IFileStructure {
    fileStructureData: {
        key: string,
        value: string
    }[]
}

const FileStructure = ({fileStructureData}: IFileStructure) => {
    return (
        <div>
            <Typography color={valueColor}>{'{'}</Typography>
            {
                fileStructureData.map(item => (
                    <Typography color={keyColor} sx={{ml: 3}}>
                        "{item.key}"<Typography display="inline" color={valueColor}>: {item.value}</Typography>
                    </Typography>
                ))
            }
            <Typography color={valueColor}>{'}'}</Typography>
        </div>
    );
};

export {FileStructure, keyColor, valueColor, commentColor};
