import React, {useState} from 'react';
import {
    Box,
    Container, Grid,
    Typography
} from "@mui/material";
import FileUpload from "react-material-file-upload";
import ChooseDataEnteringType from "./ChooseDataEnteringType";
import {HistoryFeed, type HistoryDataType} from "../../../history/HistoryFeed";
import BigButton from "../../BigButton";
import {CSRFToken} from "../../CSRFToken";

type HowAddDataType = 'custom' | 'upload'

interface IDataProcessingPage {
    chooseDataEnteringTypeLabel: string
    enterCustomDataLabel: string
    customDataEntering: React.ReactNode
    fileStructure: React.ReactNode
    onFileUpload: (file: File | undefined) => any
    onGetResultButtonClick: () => any
    getResultButtonLabel: string
    errorMessage: string
    resultComponent: React.ReactNode | null
    historyData: HistoryDataType,
    onDeleteItem: (i: number) => any
    getResultItem: (index: number) => React.ReactNode
    howAddData: HowAddDataType
    onAddDataChange: (howAddDataType: string) => any
    onSaveButtonClick: () => any
    postUrl: string
}

// entering .json files
const DataProcessingPage = ({
                                chooseDataEnteringTypeLabel,
                                enterCustomDataLabel,
                                customDataEntering,
                                fileStructure,
                                onFileUpload: customOnFileUpload,
                                onGetResultButtonClick,
                                getResultButtonLabel,
                                errorMessage,
                                resultComponent,
                                historyData,
                                onDeleteItem,
                                getResultItem,
                                howAddData,
                                onAddDataChange,
                                onSaveButtonClick,
                                postUrl
                            }: IDataProcessingPage) => {
    const [files, setFiles] = useState<File[]>([]);
    const onFilesUpload = (currentFiles: File[]) => {
        if (currentFiles.length <= 1) {
            setFiles(currentFiles)
            customOnFileUpload(currentFiles.length !== 0 ? currentFiles[0] : undefined)
        }
    }
    return (
        <Container>
            <Grid>
                <Grid item>
                    <ChooseDataEnteringType
                        chooseDataEnteringTypeLabel={chooseDataEnteringTypeLabel}
                        enterCustomDataLabel={enterCustomDataLabel}
                        onAddDataChange={event => onAddDataChange(event.target.value)}
                        howAddDataValue={howAddData}
                    />
                </Grid>

                <Grid item>
                    {
                        howAddData === "custom" ?
                            customDataEntering
                            :
                            <Box>
                                {/*<Typography variant="h5">*/}
                                {/*    Json file structure*/}
                                {/*</Typography>*/}
                                <Typography variant="h5">
                                    Структура JSON-файла
                                </Typography>
                                {fileStructure}
                                <Box sx={{my: 2}}>
                                    <FileUpload value={files} onChange={onFilesUpload}
                                                buttonText="Загрузить"
                                                // title={"Drag 1 drop some .json file here, or click to select file"}/>
                                                title={"Выберите JSON-файл или перетяните его сюда"}/>
                                </Box>
                            </Box>
                    }
                </Grid>

                <Grid item>
                    <form action={postUrl} method="post">
                        <CSRFToken/>
                        <BigButton onClick={onGetResultButtonClick} label={getResultButtonLabel} />
                    </form>
                </Grid>

                <Grid item>
                    <Typography sx={{my: 1}} color="#e53935">
                        {errorMessage}
                    </Typography>
                </Grid>

                <Grid item sx={{mt: 2}}>
                    {resultComponent}
                </Grid>

                {
                    resultComponent &&
                    <Grid item container justifyContent="center" sx={{mb: 5}}>
                        {/*<BigButton label="Save" onClick={onSaveButtonClick}/>*/}
                        <BigButton label="Сохранить" onClick={onSaveButtonClick}/>
                    </Grid>
                }

                <Grid item>
                    <HistoryFeed historyData={historyData} onDeleteItem={onDeleteItem} getResultItem={getResultItem}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export
{
    DataProcessingPage
}
export type
{
    HowAddDataType
}

