import React, {useEffect} from 'react';
import {Grid} from "@mui/material";
import Estimate from "./Estimates/Estimate";
import QuantEstimates from "./Estimates/QuantEstimates";
import {EstimatesType, KpType} from "./MetaKPDataEntering";
import {bitsToAtomsString, countUnitBits} from "../../../utils/numberProcessing";
import { subIdealIndexToIdeal } from '../../../utils/abnDataProcessing';

const getEstimatesLength = (kpType: KpType, maxIndex: number[]) => {
    const unitBitsCount = maxIndex.length
    return kpType === 'quants' ? Math.pow(2, unitBitsCount) : Math.pow(2, unitBitsCount) - 1
}

const getNewEstimates = (kpType: KpType, maxIndex: number[]) => {
    return new Array(getEstimatesLength(kpType, maxIndex))
}


interface IKPEntering {
    maxIndex: number[]
    kpType: KpType
    type: EstimatesType
    onEstimatesChange: (newEstimates: string[] | string[][]) => any
    estimates: any
    isClearInput?: boolean
    isMock?: boolean
}

const KPEntering = ({
                        maxIndex,
                        type,
                        onEstimatesChange: onEstimatesCustomChange,
                        isClearInput,
                        kpType,
                        isMock,
                        estimates
                    }: IKPEntering) => {
    useEffect(() => {
        if (isClearInput) {
            onEstimatesCustomChange(getNewEstimates(kpType, maxIndex))
        }
    }, [maxIndex, type, isClearInput, kpType, onEstimatesCustomChange]);

    const onEstimatesChange = (newValue: string, i: number, isLeft: boolean) => {
        const newEstimates = [...estimates]
        const estimatesIndex = kpType === 'quants' ? i : i - 1
        if (type === 'scalar') {
            newEstimates[estimatesIndex] = newValue
        } else {
            if (!newEstimates[estimatesIndex]) {
                newEstimates[estimatesIndex] = []
            }
            newEstimates[estimatesIndex][isLeft ? 0 : 1] = newValue
        }
        onEstimatesCustomChange(newEstimates)
    }

    const KPEstimateInputs = []

    if (maxIndex !== undefined) {
        const itemsCount = Math.pow(2, maxIndex.length)
        for (let i = (kpType === 'quants' ? 0 : 1); i < itemsCount; i++) {

            const centerLabel = `p(${bitsToAtomsString(subIdealIndexToIdeal(i, maxIndex), kpType, maxIndex)})`

            const estimatesIndex = kpType === 'quants' ? i : i - 1
            KPEstimateInputs.push(
                <Grid item key={i}
                    // hardcode
                      xs={kpType === 'disjuncts' && i > 15 ? 4 : 3}
                >
                    {
                        isMock ?
                            <QuantEstimates
                                leftValue={type === 'interval' ? estimates[estimatesIndex][0] : estimates[estimatesIndex]}
                                text={centerLabel}
                                rightValue={type === 'interval' ? estimates[estimatesIndex][1] : undefined}
                            />
                            :
                            <Estimate text={centerLabel} type={type}
                                      isClearInput={isClearInput}
                                      leftValue={type === 'interval' ? (estimates[estimatesIndex] ? estimates[estimatesIndex][0] : undefined) : estimates[estimatesIndex]}
                                      rightValue={type === 'interval' ? (estimates[estimatesIndex] ? estimates[estimatesIndex][1] : undefined) : undefined}
                                      onLeftChange={(newValue) => onEstimatesChange(newValue, i, true)}
                                      onRightChange={(newValue) => onEstimatesChange(newValue, i, false)}
                            />
                    }

                </Grid>
            )
        }
    }

    return (
        <Grid container
              rowSpacing={2}
              columnSpacing={4}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
            {KPEstimateInputs}
        </Grid>
    )
}

export {KPEntering, getNewEstimates, getEstimatesLength};

