import React, {useState} from 'react';
import {Arrow, Canvas, Edge, Label, Node, NodeProps} from "reaflow";
import {Grid, IconButton, Typography} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KnowledgePattern from "./KnowledgePattern";
import {getTextByIndex} from './utils';
import {binaryToMaxIndex, maxIndexToBinary} from "../../../../utils/numberProcessing";

type KpsType = {
    estimates: number[] | number[][],
    maxIndex: number[]
}[]

type KpsTypeWithOptionalEstimates = {
    estimates?: number[] | number[][],
    maxIndex: number[]
}[]

function getMaxGraphData(kps: KpsTypeWithOptionalEstimates) {
    const nodes = kps.map(kp => {
        return {
            id: maxIndexToBinary(kp.maxIndex),
            text: `${getTextByIndex(kp.maxIndex)}`,
        }
    })
    let edges = []
    for (let i = 0; i < kps.length; i++) {
        for (let j = i + 1; j < kps.length; j++) {
            const indexI = kps[i].maxIndex
            const indexJ = kps[j].maxIndex
            const intersection = indexI.filter(x => indexJ.includes(x));
            if (intersection.length !== 0) {
                edges.push({
                    id: `${maxIndexToBinary(indexI)}-${maxIndexToBinary(indexJ)}`,
                    from: maxIndexToBinary(indexI),
                    to: maxIndexToBinary(indexJ),
                    text: getTextByIndex(intersection)
                })
            }
        }
    }
    return {nodes, edges}
}

interface IParentSeparatorsGraph {
    kps: KpsTypeWithOptionalEstimates;
}

function ParentSeparatorsGraph({kps}: IParentSeparatorsGraph) {
    const [nodeIdToShow, setNodeIdToShow] = useState(null);
    const maxGraphData = getMaxGraphData(kps)
    let maxGraphDataHeight = Math.trunc(Math.sqrt(kps.length) + 1) * 250
    return <>
        {
            nodeIdToShow === null ?
                <Grid container justifyContent="center" alignItems="center" direction="column">
                    <Grid item sx={{mb: 2}}>
                        {/*<Typography variant="inherit">Click on the node</Typography>*/}
                        <Typography variant="inherit">Нажмите на вершину</Typography>
                    </Grid>
                    <Grid item>
                        <Canvas
                            maxHeight={maxGraphDataHeight}
                            maxWidth={500}
                            nodes={maxGraphData['nodes']}
                            edges={maxGraphData['edges']}
                            edge={<Edge selectable={false} label={<Label style={{fill: '#212121', fontSize: 20}}/>}/>}
                            arrow={<Arrow style={{fill: '#b1b1b7'}}/>}
                            node={(node: NodeProps) => (
                                <Node
                                    {...node}
                                    dragCursor="grab"
                                    dragType="all"
                                    onClick={() => {
                                        // @ts-ignore*/
                                        setNodeIdToShow(node.id)
                                    }}/>
                            )}
                        />
                    </Grid>
                </Grid>
                :
                <>
                    <IconButton size="large" onClick={() => setNodeIdToShow(null)}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <KnowledgePattern
                        maxIndex={binaryToMaxIndex(nodeIdToShow)}
                        estimates={kps.filter(kp => maxIndexToBinary(kp.maxIndex) === nodeIdToShow)[0].estimates}
                    />
                </>
        }
    </>
}

export {ParentSeparatorsGraph}
export type {KpsType}
