import React, { useRef } from "react";

const useCancellablePromises = () => {
  const pendingPromises = useRef([]);

    const appendPendingPromise = (promise: any) =>
          // @ts-ignore
    pendingPromises.current = [...pendingPromises.current, promise];

      // @ts-ignore
  const removePendingPromise = promise =>
    pendingPromises.current = pendingPromises.current.filter(p => p !== promise);

    // @ts-ignore
  const clearPendingPromises = () => pendingPromises.current.map(p => p.cancel());

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };

  return api;
};

// @ts-ignore
export const cancellablePromise = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
        // @ts-ignore
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      // @ts-ignore
      error => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

export const delay = (n: number) => new Promise(resolve => setTimeout(resolve, n))

const useClickPreventionOnDoubleClick = (onClick: () => void, onDoubleClick: () => void) => {
  const api = useCancellablePromises();

  const handleClick = () => {
    api.clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    api.appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick);
        onClick();
      })
      .catch(errorInfo => {
        api.removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    api.clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};

interface IClickableBox {
    onDoubleClick: () => void
    onClick: () => void
    children?: React.ReactNode
}

const ClickableBox = ({ onClick, onDoubleClick, children }: IClickableBox) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick);

  return (
    <div onClick={handleClick} onDoubleClick={handleDoubleClick}>
        {children}
    </div>
  );
};

export default ClickableBox