import React, {useEffect, useState} from 'react';
import {type KpType, EstimatesType} from "../../../common/entering/MetaKPDataEntering";
import {ConsistencyCheckResult} from "./ConsistencyCheckResult";
import {addHistoryItem, deleteHistoryItem, getHistoryData, historyTypes} from "../../../history/historyProcessing";
import {DataProcessingPage, type HowAddDataType} from "../../../common/entering/DataProcessingPage/DataProcessingPage";
import CustomDataEntering from "./CustomDataEntering";
import {type HistoryDataType as HistoryDataPlateType} from "../../../history/HistoryFeed";
import {capitalizeFirstLetter} from "../../../../utils/stringProcessing";
import ConsistencyHistoryDialogContent from "./ConsistencyHistoryDialogContent";
import {checkAndGetQuants, checkAndGetQuantsOld} from "../../../../utils/quantsProcessing";
import {FileStructure} from "../../../common/displaying/FileStructure";
import { getKpAndQuantsDataFromFile, saveReconciliationKpResult, saveQuantKpResult} from "./fileHandling";
import {paths} from "../../../../config";
import {getDataFromServer} from '../../../../utils/httpUtils';
import {binaryToMaxIndex, countUnitBits, maxIndexToBinary} from "../../../../utils/numberProcessing";

const INITIAL_KP_TYPE = 'conjuncts'
const INITIAL_ESTIMATES_TYPE = 'scalar'
const INITIAL_MAX_INDEX = [0, 1]
const INITIAL_QUANT_COUNT = 3
const HISTORY_TYPE = historyTypes.kpReconciliation

interface TrainingRequest {
    quants: number[] | number[][],
    estimatesType: EstimatesType,
    maxIndex: number[]
}

interface TrainingResult {
    inputQuants: number[] | number[][],
    outputEstimates: number[] | number[][] | undefined,
    isConsistent: boolean,
    maxIndex: number[],
}

interface KPTrainingResponse extends TrainingResult {
    errorMessage: string
}

interface HistoryDataItem extends KPTrainingResponse {
    date: string,
}

type HistoryData = HistoryDataItem[] | never[]


const setReconciliationData = (
    reconciliationData: TrainingRequest,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: TrainingResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    getDataFromServer(reconciliationData, paths.training_algorithm, (response) => {
        if (response.errorMessage !== '') {
            onGetErrorMessage(response.errorMessage)
            onGetResultData(undefined)
        } else if (response.isConsistent) {
            onGetResultData({
                isConsistent: true,
                // @ts-ignore
                outputEstimates: response.outputEstimates,
                inputQuants: response.inputQuants,
                maxIndex: response.maxIndex,
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        } else {
            onGetResultData({
                isConsistent: false,
                outputEstimates: undefined,
                inputQuants: response.inputEstimates,
                maxIndex: [],
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        }
    }, (error: any) => {
        onGetErrorMessage(error.toString())
    })

}

const reconcile = (
    howAddEstimate: HowAddDataType,
    file: File | undefined,
    quants: string[] | string[][] | undefined,
    quantsCount: number | undefined,
    estimatesType: EstimatesType,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: TrainingResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any,
    maxIndex : number[]
) => {
    if (howAddEstimate === "upload") {
        getKpAndQuantsDataFromFile(file, result => {
            setReconciliationData(
                // @ts-ignore
                result,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }, errorMessage => {
            onGetResultData(undefined)
            onGetErrorMessage(errorMessage)
        })
    } else {  // howAddEstimate === "custom"
        // @ts-ignore
        if (quantsCount === undefined) {
            // onGetErrorMessage("Enter quants count")
            onGetErrorMessage("Введите число квантов")
            onGetResultData(undefined)
            return
        }
        // @ts-ignore
        if (maxIndex === undefined) {
            // onGetErrorMessage("Enter max index")
            onGetErrorMessage("Введите индексы атомов")
            onGetResultData(undefined)
            return
        }

        const result = checkAndGetQuantsOld(quants, quantsCount, estimatesType)
        if (result['errorMessage'] !== '') {
            onGetResultData(undefined)
            onGetErrorMessage(result['errorMessage'])
        } else {
            const reconciliationData = {
                // @ts-ignore
                quants: result['estimates'],
                estimatesType: estimatesType,
                // @ts-ignore
                maxIndex: maxIndex,
            }
            setReconciliationData(
                // @ts-ignore
                reconciliationData,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }
    }
}

const getNewQuantsData = (quantsCount: number | undefined, maxIndex: number[]) => {
    const bitsCount = maxIndex.length
    return Array(quantsCount).fill(undefined).map(() => Array(bitsCount))
}

const KPTraining = () => {
    const [howAddEstimate, setHowAddEstimate] = React.useState<HowAddDataType>('custom');
    const [quantsData, setQuantsData] = React.useState(getNewQuantsData(INITIAL_QUANT_COUNT, INITIAL_MAX_INDEX))
    const [kpType, setKpType] = React.useState<KpType>(INITIAL_KP_TYPE)
    const [quantsCount, setQuantsCount] = React.useState<number | undefined>(INITIAL_QUANT_COUNT)
    const [maxIndex, setMaxIndex] = React.useState<number[]>(INITIAL_MAX_INDEX)
    const [estimatesType, setEstimatesType] = React.useState<EstimatesType>(INITIAL_ESTIMATES_TYPE)
    const [isClearInput, setIsClearInput] = React.useState(true)
    const [resultData, setResultData] = React.useState<TrainingResult | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState('')
    const [file, setFile] = useState<File | undefined>(undefined);
    // @ts-ignore
    const [historyPlateData, setHistoryPlateData] = React.useState<HistoryDataPlateType>([]);
    const [historyData, setHistoryData] = React.useState<HistoryData>(getHistoryData(HISTORY_TYPE));

    useEffect(() => {
        setErrorMessage('')
    }, [estimatesType, quantsData, estimatesType, quantsCount, kpType, howAddEstimate])

    useEffect(() => {
        setIsClearInput(true)
    }, [estimatesType, quantsCount, kpType, howAddEstimate, maxIndex])

    useEffect(() => {
        setIsClearInput(false)
    }, [quantsData])

    useEffect(() => {
        setQuantsData(getNewQuantsData(quantsCount, maxIndex))
    }, [isClearInput, maxIndex, quantsCount])

    useEffect(() => {
        const content = historyData.map(historyItem => (
            {
                date: historyItem.date,
                title: capitalizeFirstLetter('конъюнкты'),
                hasSuccess: historyItem.isConsistent
            }
        ))
        setHistoryPlateData(
            {
                // successMessage: 'Trained',
                // failureMessage: 'Unable to train',
                successMessage: 'Обучен',
                failureMessage: 'Ошибка при обучении',
                content
            }
        )
    }, [historyData])

    const onDeleteHistoryItem = (i: number) => {
        const newHistory = deleteHistoryItem(HISTORY_TYPE, i)
        setHistoryData(newHistory)
    }

    const getDialogHistoryItem = (historyIndex: number) => {
        const historyItem = historyData[historyIndex]
        return <ConsistencyHistoryDialogContent
            kpType={'conjuncts'}
            inputEstimates={historyItem['inputQuants']}
            narrowedEstimates={historyItem['outputEstimates']}
            isConsistent={historyItem['isConsistent']}
            maxIndex={historyItem['maxIndex']}
        />
    }

    const onQuantsChange = (quantIndex: number, atomIndex: number, newValue: boolean | undefined) => {
        const newQuantsData = [...quantsData]
        newQuantsData[quantIndex][atomIndex] = newValue
        setQuantsData(newQuantsData)
    }

    return (
        <DataProcessingPage
            howAddData={howAddEstimate}
            // @ts-ignore
            onAddDataChange={setHowAddEstimate}
            // chooseDataEnteringTypeLabel="How to add quants?"
            chooseDataEnteringTypeLabel="Как добавлять кванты?"
            // enterCustomDataLabel="Enter quants"
            enterCustomDataLabel="Введите кванты"
            customDataEntering={
                <CustomDataEntering
                    estimateType={estimatesType}
                    onChangeEstimatesType={setEstimatesType}
                    maxIndex={maxIndex}
                    onMaxIndexChange={setMaxIndex}
                    onQuantChange={onQuantsChange}
                    quantCount={quantsCount}
                    onQuantCountChange={setQuantsCount}
                    isClearInput={isClearInput}
                />
            }
            fileStructure={
                <FileStructure fileStructureData={[
                    { key: "maxIndex", value: "[number, ...]" },
                    { key: "quants", value: " [[number or *, ...], ...]," },
                    { key: "estimatesType", value: "string (\"scalar\" or \"interval\")" },
                ]}/>
            }
            onFileUpload={setFile}
            postUrl={paths.training_representation}
            onGetResultButtonClick={() => reconcile(
                howAddEstimate,
                file,
                quantsData,
                quantsCount,
                estimatesType,
                setErrorMessage,
                setResultData,
                (newItem: any) => {
                    addHistoryItem(HISTORY_TYPE, newItem)
                    setHistoryData(getHistoryData(HISTORY_TYPE))
                },
                maxIndex,
            )}
            // getResultButtonLabel="Train"
            getResultButtonLabel="Обучить"
            errorMessage={errorMessage}
            resultComponent={
                resultData
                    ? <ConsistencyCheckResult
                        // @ts-ignore
                        kpType={'conjuncts'}
                        // @ts-ignore
                        estimates={resultData['outputEstimates']}
                        // @ts-ignore
                        maxKPIndex={resultData['maxIndex']}
                        isConsistent={resultData['isConsistent']}
                    />
                    : null
            }
            historyData={historyPlateData}
            onDeleteItem={onDeleteHistoryItem}
            getResultItem={getDialogHistoryItem}
            // @ts-ignore
            onSaveButtonClick={() => saveQuantKpResult(resultData)}
        />
    )
}

export {KPTraining};
export type {TrainingResult};
