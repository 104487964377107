import React from 'react';
import {Button, Grid, Typography} from "@mui/material";
import {alpha} from "@mui/material/styles";


interface INumberInput {
    onNumberChange: (number: number) => any
    numberValue: number
    min?: number
    max?: number
}

const SimpleNumberInput = ({onNumberChange, min, max, numberValue}: INumberInput) => {
    const increaseNumber = () => {
        if (max && numberValue < max ) {
            const newNumber = numberValue + 1
            onNumberChange(newNumber)
        }
    };

    const decreaseNumber = () => {
        if (min && numberValue > min) {
            const newNumber = numberValue - 1
            onNumberChange(newNumber)
        }
    };
    const height = 35
    const width = 30

    return (
        <Grid container direction="row">
            <Grid item>
                <Button onClick={decreaseNumber} sx={{
                    minWidth: 1,
                    width,
                    height,
                    backgroundColor: '#ffebee',
                    borderRadius: '3px 0px 0px 3px'
                }}>-</Button>
            </Grid>
            <Grid item container sx={{
                px: 2,
                backgroundColor: alpha('#ffebee', 0.4),
                height,
                width,
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Typography textAlign={'center'}>{numberValue}</Typography>
            </Grid>
            <Grid item>
                <Button onClick={increaseNumber} sx={{
                    minWidth: 1,
                    width,
                    height,
                    backgroundColor: '#ffebee',
                    borderRadius: '0px 3px 3px 0px'
                }}>+</Button>
            </Grid>
        </Grid>
    );
};

export default SimpleNumberInput;
