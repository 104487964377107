import React from 'react';
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";

interface IChooseDataEnteringTypeRadioGroup {
    chooseDataEnteringTypeLabel: string
    enterCustomDataLabel: string
    howAddDataValue: string
    onAddDataChange: (event: React.ChangeEvent<HTMLInputElement>) => any
}

const ChooseDataEnteringType = ({
                                              chooseDataEnteringTypeLabel,
                                              enterCustomDataLabel,
                                              howAddDataValue,
                                              onAddDataChange
                                          }: IChooseDataEnteringTypeRadioGroup) => {
    return (
        <FormControl sx={{mb: 6}}>
            <FormLabel id="demo-radio-buttons-group-label">
                {chooseDataEnteringTypeLabel}
            </FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="custom"
                name="radio-buttons-group"
            >
                <FormControlLabel
                    value="upload"
                    control={
                        <Radio
                            checked={howAddDataValue === 'upload'}
                            onChange={onAddDataChange}
                            value="upload"
                        />
                    }
                    // label="Upload a file"/>
                    label="Загрузить файл"/>
                <FormControlLabel
                    value="custom"
                    control={
                        <Radio
                            checked={howAddDataValue === 'custom'}
                            onChange={onAddDataChange}
                            value="custom"
                        />}
                    label={enterCustomDataLabel}/>
            </RadioGroup>
        </FormControl>
    );
};

export default ChooseDataEnteringType;
