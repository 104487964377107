import React, {useEffect} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import SimpleNumberInput from "../../../common/entering/SimpleNumberInput";
import MaxIndexEntering from "../../../common/entering/MaxIndexEntering";

type IndexInputType = number[]

interface IIndicesEntering {
    indices: IndexInputType[]
    onIndicesChange: (newIndices: IndexInputType[]) => any
}

const IndicesEntering = ({indices, onIndicesChange: customOnIndicesChange}: IIndicesEntering) => {
    const [indicesCount, setIndicesCount] = React.useState<number>(1)

    const onIndicesCountChange = (newValue: number) => {
        setIndicesCount(newValue)
    }

    useEffect(() => {
        let newIndices
        if (indicesCount < indices.length) {
            newIndices = [...indices.slice(0, indices.length - 1)]
        } else {
            newIndices = [...indices, []]
        }
        customOnIndicesChange(newIndices)
    }, [indicesCount])

    const onIndicesChange = (index: number, value: IndexInputType) => {
        let newIndices = [...indices]
        newIndices[index] = value
        customOnIndicesChange(newIndices)
    }
    let emptyArray = [];
    for (let i = 0; i < indicesCount; i++) {
        emptyArray.push(i);
    }

    return (
        <Box>
            <Grid container direction="row" alignItems="center" sx={{mt: 0}}>
                <Grid item>
                    {/*<Typography variant="h6" sx={{mr: 3}}>*/}
                    {/*    Knowledge patterns quantity:*/}
                    {/*</Typography>*/}
                    <Typography variant="h6" sx={{mr: 3}}>
                        Количество моделей фрагментов знаний:
                    </Typography>
                </Grid>
                <Grid item>
                    <SimpleNumberInput min={1} max={30} onNumberChange={onIndicesCountChange} numberValue={indicesCount}/>
                </Grid>
            </Grid>

            <Grid container
                  sx={{my: 3}}
                  rowSpacing={2}
                  columnSpacing={4}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start">
                {
                    emptyArray.map((item, index) => {
                        return (
                            <Grid item key={index}>
                                <MaxIndexEntering maxIndexValue={indices[index]}
                                                  onMaxIndexChange={value => onIndicesChange(index, value)}
                                                  maxAtoms={10}
                                                  width={125}
                                />
                            </Grid>
                        )
                    })
                }
            </Grid>

        </Box>
    );
};

export default IndicesEntering;
