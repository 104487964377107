import React from 'react';
import {Grid, Typography} from "@mui/material";
import {KpType} from "../../../common/entering/MetaKPDataEntering";
import KpResult from "../../../common/displaying/KpResult";

interface IConsistencyCheckResult {
    estimates?: any
    isConsistent: boolean
    maxKPIndex?: number[]
    kpType: KpType
}

const ConsistencyCheckResult = ({
                                    estimates,
                                    maxKPIndex,
                                    isConsistent,
                                    kpType
                                }: IConsistencyCheckResult) => {
    return (
        <Grid>
            <Grid item>
                <Typography variant="h3" textAlign="center">
                    Результат
                </Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" width={"100%"}>
                {
                        <>
                            <KpResult
                                kpType={kpType}
                                estimates={estimates}
                                // @ts-ignore
                                maxKPIndex={maxKPIndex}/>
                        </>
                }
            </Grid>
        </Grid>
    )
}

export {ConsistencyCheckResult};
