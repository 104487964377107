import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import {KpType} from '../../../common/entering/MetaKPDataEntering';
import KpResult from "../../../common/displaying/KpResult";
import BigButton from "../../../common/BigButton";
import {saveReconciliationKpResult} from "./fileHandling";

interface IConsistencyHistoryDialogContent {
    kpType: KpType
    inputEstimates: number[] | number[][]
    narrowedEstimates: number[] | number[][] | undefined
    isConsistent: boolean
    maxIndex: number[]
}

const ConsistencyHistoryDialogContent = ({
                                             kpType,
                                             isConsistent,
                                             narrowedEstimates,
                                             inputEstimates,
                                             maxIndex
                                         }: IConsistencyHistoryDialogContent) => {
    return (
        <Box sx={{m: 3, minWidth: 900}}>
            <Typography variant="h3" textAlign="center" sx={{mb: 3}}>
                Введенный ФЗ
            </Typography>

            {
                inputEstimates && <KpResult
                    // @ts-ignore
                    kpType={kpType}
                    // @ts-ignore
                    estimates={inputEstimates}
                    // @ts-ignore
                    maxKPIndex={maxIndex}/>
            }

            <Typography variant="h3" textAlign="center" sx={{mt: 4}}>
                Результат
            </Typography>
            {
                // @ts-ignore
                isConsistent ?
                    (
                        narrowedEstimates && (
                            <Grid container justifyContent="center" direction="column">
                                <Grid item>
                                    <Typography color="#81c784" variant="h5" textAlign="center" sx={{my: 2}}>
                                        ФЗ непротиворечив!
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <KpResult
                                        // @ts-ignore
                                        kpType={kpType}
                                        // @ts-ignore
                                        estimates={narrowedEstimates}
                                        // @ts-ignore
                                        maxKPIndex={maxIndex}
                                    />
                                </Grid>
                            </Grid>

                        )
                    )
                    :
                    <Typography color="#e53935" variant="h4" textAlign="center" sx={{my: 2}}>
                        ФЗ противоречив 😞
                    </Typography>
            }

            <Grid item container justifyContent="center" sx={{mt: 8}}>
                <BigButton onClick={() => saveReconciliationKpResult({
                    kpType,
                    inputEstimates,
                    maxIndex,
                    narrowedEstimates,
                    isConsistent
                })}
                           // label="Save"
                           label="Сохранить"
                />
            </Grid>
        </Box>
    );
};

export default ConsistencyHistoryDialogContent;
