import React from 'react';
import {Grid, Typography} from "@mui/material";
import {ParentSeparatorsGraph, type KpsType} from "../../../common/displaying/graphs/ParentSeparatorsGraph";
import {EvidenceDataType} from "../../../common/entering/EvidenceEntering";
import KpResult from "../../../common/displaying/KpResult";

interface IGlobalPosterioriResult {
    kps: KpsType | undefined
    evidence: EvidenceDataType
}

const GlobalPosterioriResult = ({kps, evidence}: IGlobalPosterioriResult) => {
    return (
        <Grid container justifyContent="center" alignItems="center" width={"100%"} sx={{mt: 2, mb: 4}}>
            <Grid item sx={{my: 2}}>
                {/*<Typography variant="h3" textAlign="center">Result</Typography>*/}
                <Typography variant="h3" textAlign="center">Результат</Typography>
            </Grid>
            {
                kps ?
                    <Grid item container justifyContent="center" alignItems="center" width={"100%"}>
                        <Grid item>
                            <ParentSeparatorsGraph kps={kps}/>
                        </Grid>
                    </Grid>
                    :
                    <Typography textAlign="center">Some error happened</Typography>
            }
            {
                evidence.evidenceType !== 'deterministic' && (
                    <>
                        <Grid item>
                            {/*<Typography variant="h5" textAlign="center">Evidence</Typography>*/}
                            <Typography variant="h5" textAlign="center">Свидетельство</Typography>
                        </Grid>
                        <Grid item>
                            <KpResult
                                kpType="conjuncts"
                                // @ts-ignore
                                estimates={evidence.data.estimates}
                                // @ts-ignore
                                maxKPIndex={evidence.data.maxIndex}/>
                        </Grid>
                    </>
                )
            }
        </Grid>
    );
};

export default GlobalPosterioriResult;

// 0 1 2      0.41 0.6 0.3 0.46 0.06 0.25 0.05
// 0 1 3      0.41 0.6 0.3 0.5  0.2  0.3  0.1
//   1 4           0.6     0.5       0.3

// 0 1        [0.2, 0.3]  [0.3, 0.4]  [0.05, 0.1]