import React from 'react';
import {Box, Container, Grid, Paper, Typography} from "@mui/material";

const NotFoundPage = () => {
    return (
        <Container sx={{my: 5, mt: 25}}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <Paper elevation={3}>
                        <Typography fontSize={200} color="#78002e" sx={{mx: 5}}>
                            404
                        </Typography>
                        <Box sx={{backgroundColor: '#ad1457', height: 80, borderRadius: "0px 0px 5px 5px"}}/>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

export default NotFoundPage;
