import React, {SyntheticEvent, useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import {countUnitBits} from "../../../utils/numberProcessing";
import {Autocomplete} from "@mui/material";
import {HistoryDataType as HistoryDataPlateType} from "../../history/HistoryFeed";

interface IMaxIndexEntering {
    maxIndexValue: number[] // TODO: change
    onMaxIndexChange: (maxIndex: number[]) => void
    maxAtoms: number
    minAtoms?: number
    label?: string
    width?: number
}

const getValue = (maxIndex: number[]) => maxIndex ? maxIndex.map(v => v.toString()) : ['']

const MaxIndexEntering = ({width, minAtoms, label, maxIndexValue, onMaxIndexChange, maxAtoms}: IMaxIndexEntering) => {
    // <TextField
    //     id="outlined-number"
    //     label={label ? label : "Max index"}
    //     type="number"
    //     value={maxIndexValue}
    //     // @ts-ignore
    //     onChange={(event) => {
    //         const value = event.target.value
    //
    //         const newNumber = value === '' ? undefined : Number(value)
    //         if (newNumber === undefined || (newNumber >= (minAtoms ? minAtoms : 1) && countUnitBits(newNumber) <= maxAtoms)) {
    //             onMaxIndexChange(newNumber)
    //         }
    //     }}
    //     onKeyDown={evt => {
    //         if (evt.key.match(/[.,+-]/)) {
    //             evt.preventDefault()
    //         }
    //     }}
    //     sx={{width: width ? width : 175}}
    //     InputLabelProps={{
    //         shrink: true,
    //     }}
    // />

    const [strValue, setStrValue] = useState<string[]>(getValue(maxIndexValue));
    useEffect(() => {
        setStrValue(getValue(maxIndexValue))
    }, [maxIndexValue])

    return (
        <Autocomplete
        value={strValue}
        // onInputChange={(event, value) => {
        //     if (!value.match(/^\d*$/)) {
        //         event.preventDefault()
        //     }
        // }}
        onChange={(event, values) => onMaxIndexChange(values.map(value => Number(value)))}
        multiple
        id="tags-filled"
        options={[]}
        style={{width: 300}}
        freeSolo
        renderInput={(params) => (
            <TextField
                {...params}
                label="Индексы атомов"
                placeholder="Введите индексы атомов"
            />
        )}
    />
    )
}

export default MaxIndexEntering;
