import React from 'react';
import Select from "@mui/material/Select";
import {FormControl, InputLabel, MenuItem, Typography} from "@mui/material";
import {capitalizeFirstLetter} from "../../../utils/stringProcessing";

interface IStyledFormControl {
    values: string[]
    value: string
    label: string
    onValueChange: (newValue: any) => any
}

const StyledFormControl = ({values, label, onValueChange, value}: IStyledFormControl) => {
    const getItemText = (currentValue: string) => {
        switch (currentValue) {
            case 'conjuncts':
                return 'Конъюнкты'
            case 'quants':
                return 'Кванты'
            case 'disjuncts':
                return 'Дизъюнкты'
            case 'scalar':
                return 'Скалярные'
            case 'interval':
                return 'Интервальные'
            case 'stochastic':
                return 'Стохастическое'
            case 'imprecise':
                return 'Неточное'
            case 'deterministic':
                return 'Детерминированное'

        }
    }

    return (
        <FormControl sx={{width: 205}}>
            <InputLabel>
                <Typography sx={{backgroundColor: 'white', pr: 0.9}}>
                    {label}
                </Typography>
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                onChange={event => onValueChange(event.target.value.toString())}
            >
                {
                    values.map(value => (
                            <MenuItem value={value} key={value}>
                                {getItemText(value)}
                            </MenuItem>
                        )
                    )
                }
            </Select>
        </FormControl>
    )
}

export default StyledFormControl;
