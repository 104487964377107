import {GlobalPosterioriInferResult} from "../components/pages/ParentSeparatorsGraphPage/Posteriori/PosterioriInfer";

const getDataFromFile = (
    file: File,
    onSuccess: (data: object) => any,
    onError: (errorMessage: string) => any
) => {
    let reader = new FileReader();
    reader.readAsText(file);

    reader.onload = function () {
        if (typeof reader.result === "string") {
            onSuccess(JSON.parse(reader.result))
        } else {
            // onError("Something wrong with file reading")
            onError("Что-то не так с чтением файла")
        }
    };

    // @ts-ignore
    reader.onerror = (fileReader, event) => {
        // onError("Something wrong with file reading")
        onError("Что-то не так с чтением файла")
    }
}

const saveObjectToFile = (obj: object, name: string) => {
    const file = new File([JSON.stringify(obj, null, 2)], `${name}.json`, {
        type: 'application/json',
    })

    const link = document.createElement('a')
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}


export {getDataFromFile, saveObjectToFile}
