import {getDataFromFile, saveObjectToFile} from "../../../../utils/fileHandling";
import {checkAndGetEstimates} from "../../../../utils/abnDataProcessing";
import {isNumeric} from "../../../../utils/numberProcessing";
import {GlobalPosterioriInferRequest, GlobalPosterioriInferResult} from "./PosterioriInfer";

const getKpsDataFromFile = (
    currentFile: File | undefined,
    onSuccess: (data: GlobalPosterioriInferRequest) => any,
    onError: (errorMessage: string) => any
) => {
    if (currentFile === undefined) {
        onError("Upload a file")
    }

    // @ts-ignore
    getDataFromFile(currentFile, (data => {
        for (const field of ['kps', 'evidence']) {
            if (!data.hasOwnProperty(field)) {
                onError(`Field '${field}' not found`)
                return
            }
        }

        // @ts-ignore
        let evidence = data['evidence']

        for (const field of ['type', 'data']) {
            if (!evidence.hasOwnProperty(field)) {
                onError(`Field '${field}' not found in evidence`)
                return
            }
        }

        const evidenceType = evidence['type']

        if (evidenceType !== 'stochastic' &&
            evidenceType !== 'imprecise' &&
            evidenceType !== 'deterministic') {
            onError(`Incorrect evidence type`)
            return
        }

        if (evidenceType === 'deterministic') {
            for (const field of ['positiveIndex', 'negativeIndex']) {
                if (!evidence['data'].hasOwnProperty(field)) {
                    onError(`Field '${field}' not found in deterministic evidence data`)
                    return
                }
            }
            const positiveIndex = evidence['data']['positiveIndex']
            const negativeIndex = evidence['data']['negativeIndex']
            if (!isNumeric(positiveIndex) ||
                (!isNumeric(negativeIndex) && negativeIndex !== undefined) ||
                Number(positiveIndex) < 0 ||
                Number(negativeIndex) < 0) {
                onError(`Incorrect deterministic data`)
                return
            }

            evidence = {
                evidenceType,
                data: {positiveIndex, negativeIndex}
            }
        } else {
            for (const field of ['maxIndex', 'estimates']) {
                if (!evidence['data'].hasOwnProperty(field)) {
                    onError(`Field '${field}' not found in evidence data`)
                    return
                }
            }

            const maxIndex = evidence['data']['maxIndex']
            const estimates = evidence['data']['estimates']

            if (!isNumeric(maxIndex)) {
                onError(`Incorrect max index for evidence`)
                return
            }

            const estimatesType = evidenceType === 'stochastic' ? 'scalar' : 'interval'
            const estimatesResult = checkAndGetEstimates(estimates, 'conjuncts', maxIndex, estimatesType)
            if (estimatesResult.errorMessage !== '') {
                onError(`Incorrect evidence estimates`)
                return
            }

            evidence = {
                evidenceType,
                data: {maxIndex, estimates}
            }
        }

        // @ts-ignore
        const kps = data['kps']

        if (!Array.isArray(kps) || kps.length === 0) {
            onError(`Incorrect kps data`)
            return
        }

        for (const kp of kps) {
            if (typeof kp !== "object") {
                onError(`Incorrect data for some kp`)
                return
            }
            for (const field of ['maxIndex', 'estimates']) {
                if (!kp.hasOwnProperty(field)) {
                    onError(`Field '${field}' not found for some kp`)
                    return
                }
            }

            // @ts-ignore
            const estimates = kp['estimates']

            if (!Array.isArray(estimates) || estimates.length === 0) {
                onError(`Incorrect estimates for some kp`)
                return
            }

            // @ts-ignore
            const maxIndex = kp['maxIndex']

            if (!isNumeric(maxIndex)) {
                onError(`Incorrect max index for some kp`)
                return
            }

            const estimatesType = isNumeric(estimates[0].toString()) ? 'scalar' : 'interval'
            const estimatesResult = checkAndGetEstimates(estimates, 'conjuncts', maxIndex, estimatesType)
            if (estimatesResult.errorMessage !== '') {
                onError(`Incorrect estimates for some kp`)
                return
            }
        }

        onSuccess({kps, evidence})
    }), (errorMessage => {
        onError(errorMessage)
    }))
}

const savePosterioriInferResult = (result: GlobalPosterioriInferResult) => {
    saveObjectToFile(result, "posteriori_infer")
}

export {getKpsDataFromFile, savePosterioriInferResult}
