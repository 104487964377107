import React from 'react';
import {Box, Grid, Typography} from "@mui/material";
import SimpleNumberInput from "../../../../common/entering/SimpleNumberInput";
import AllKPEntering from "./AllKpEntering";
import {EvidenceEntering, EvidenceInputDataType} from "../../../../common/entering/EvidenceEntering";

interface IKpsEntering {
    onKpsChange: (index: number, newEstimates: string[] | string[][], maxIndex: number[]) => any
    onEvidenceDataChange: (data: EvidenceInputDataType) => any
    onKpsCountChange: (kpsCount: number) => any
}

const KpsEntering = ({onKpsChange, onEvidenceDataChange, onKpsCountChange: customOnKpsCountChange}: IKpsEntering) => {
    const [kpsCount, setKpsCount] = React.useState<number>(1)

    const onKpsCountChange = (kpsCount: number) => {
        setKpsCount(kpsCount)
        customOnKpsCountChange(kpsCount)
    }

    return (
        <Box sx={{mb: 5}}>
            {/*<Typography variant="h4">Knowledge patterns</Typography>*/}
            <Typography variant="h4">Модели фрагментов знаний</Typography>
            <Grid container direction="row" alignItems="center" spacing={3} sx={{mt: 0}}>
                <Grid item>
                    {/*<Typography variant="h6">*/}
                    {/*    Quantity:*/}
                    {/*</Typography>*/}
                    <Typography variant="h6">
                        Количество:
                    </Typography>
                </Grid>
                <Grid item>
                    <SimpleNumberInput min={1} max={10} onNumberChange={onKpsCountChange} numberValue={kpsCount}/>
                </Grid>
            </Grid>
            <AllKPEntering kpsCount={kpsCount} onKpsChange={onKpsChange}/>


            {/*<Typography variant="h4" sx={{mt: 5, mb: 4}}>Evidence</Typography>*/}
            <Typography variant="h4" sx={{mt: 5, mb: 4}}>Свидетельство</Typography>
            <EvidenceEntering onEvidenceDataChange={onEvidenceDataChange}/>
        </Box>
    );
};

export default KpsEntering;
