import React from 'react';
import {KpsType, ParentSeparatorsGraph} from "../../../common/displaying/graphs/ParentSeparatorsGraph";
import {Box, Grid, Typography} from "@mui/material";
import BigButton from "../../../common/BigButton";
import {savePosterioriInferResult} from "./fileHandling";
import {EvidenceDataType} from "../../../common/entering/EvidenceEntering";
import KpResult from "../../../common/displaying/KpResult";
import {bitsToAtoms} from "../../../common/displaying/graphs/utils";
import {numberToBinary} from "../../../../utils/numberProcessing";

interface IPosterioriHistoryDialogContent {
    inputKps: KpsType,
    resultKps: KpsType,
    evidence: EvidenceDataType
}

const PosterioriHistoryDialogContent = ({inputKps, resultKps, evidence}: IPosterioriHistoryDialogContent) => {
    return (
        <Box sx={{m: 3, minWidth: 900}}>
            <Typography variant="h3" textAlign="center" sx={{mt: 4, mb: 3}}>
                Результат
            </Typography>
            {
                inputKps && <ParentSeparatorsGraph kps={resultKps}/>
            }

            <Typography variant="h3" textAlign="center" sx={{mb: 3}}>
                Начальные оценки
            </Typography>
            {
                inputKps && <ParentSeparatorsGraph kps={inputKps}/>
            }

            <Typography variant="h4" textAlign="center" sx={{mb: 3}}>
                Свидетельство
            </Typography>
            {
                evidence.evidenceType !== 'deterministic' ? (
                    <Grid item>
                        <KpResult
                            kpType="conjuncts"
                            // @ts-ignore
                            estimates={evidence.data.estimates}
                            // @ts-ignore
                            maxKPIndex={evidence.data.maxIndex}/>
                    </Grid>
                ) : (
                    <>
                        {
                            // @ts-ignore
                            evidence.data.positiveIndex &&
                            <>
                                <Typography textAlign="center">
                                    {/*<Typography display="inline" variant="h5">*/}
                                    {/*    {'Positive conjunct: '}*/}
                                    {/*</Typography>*/}
                                    <Typography display="inline" variant="h5">
                                        {'Конъюнкт с положительными означиваниями: '}
                                    </Typography>
                                    <Typography display="inline" variant="h5" color="#ac0800">
                                        {/*// @ts-ignore*/}
                                        {bitsToAtoms(numberToBinary(evidence.data.positiveIndex))}
                                    </Typography>
                                </Typography>
                            </>
                        }
                        {
                            // @ts-ignore
                            evidence.data.negativeIndex &&
                            <>
                                <Typography textAlign="center">
                                    {/*<Typography display="inline" variant="h5">*/}
                                    {/*    {'Negative conjunct: '}*/}
                                    {/*</Typography>*/}
                                    <Typography display="inline" variant="h5">
                                        {'Конъюнкт с отрицательными означиваниями: '}
                                    </Typography>
                                    <Typography display="inline" variant="h5" color="#ac0800">
                                        {/*// @ts-ignore*/}
                                        {bitsToAtoms(numberToBinary(evidence.data.negativeIndex))}
                                    </Typography>
                                </Typography>
                            </>
                        }
                    </>
                )
            }

            <Grid item container justifyContent="center" sx={{mt: 8}}>
                <BigButton
                    onClick={() => savePosterioriInferResult({resultKps, inputKps, evidence})}
                    // label="Save"
                    label="Сохранить"
                />
            </Grid>
        </Box>
    );
};

export default PosterioriHistoryDialogContent;
