import {maxIndexToBinary} from "../../../../utils/numberProcessing";

function getTextByIndex(index: number[]) {
    const binaryRepresentation = maxIndexToBinary(index)
    return bitsToAtoms(binaryRepresentation)
}

const lower_indices = ['₀', '₁', '₂', '₃', '₄', '₅', '₆', '₇', '₈', '₉']

function numberToLowerIndices(number: number) {
    return number.toString().split('').map(char => lower_indices[Number(char)])
}

function bitsToAtoms(bits: string, isDisjuncts?: boolean) {
    const bitsCount = bits.length
    let text: string[] | string = []
    for (let i = bitsCount - 1; i >= 0; i--) {
        let currentBit = bits.charAt(i)
        if (currentBit === "1") {
            text.unshift(`x${numberToLowerIndices(bitsCount - i - 1)}`)
        }
    }
    if (text.length === 0) {
        return "ε"
    }
    text = text.join(isDisjuncts ? ' v ' : '')
    return text
}

export {getTextByIndex, bitsToAtoms}
