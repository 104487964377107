import React from 'react';
import {Grid} from "@mui/material";
import {KPEntering} from "../entering/KPEntering";
import {KpType} from "../entering/MetaKPDataEntering";
import KnowledgePattern from "./graphs/KnowledgePattern";
import {countUnitBits} from "../../../utils/numberProcessing";

interface IKPResult {
    kpType: KpType
    estimates: any
    maxKPIndex: number[]
}

const KpResult = ({kpType, estimates, maxKPIndex}: IKPResult) => {
    return (
        <Grid sx={{my: 2}} width={"100%"} container justifyContent="center" alignItems="center">
            {
                kpType === 'quants' || maxKPIndex.length > 4 ?
                    <Grid item width={"100%"} container justifyContent="center" alignItems="center">
                        <KPEntering estimates={estimates} kpType={kpType}
                            // @ts-ignore
                                    maxIndex={maxKPIndex} onEstimatesChange={() => {}}
                                    type={typeof estimates[0] === "number" ? 'scalar' : 'interval'} isMock />
                    </Grid>
                    :
                    <Grid item width={"100%"} container justifyContent="center" alignItems="center">
                        <KnowledgePattern
                            isDisjuncts={kpType === 'disjuncts'}
                            // @ts-ignore
                            maxIndex={maxKPIndex} estimates={estimates}/>
                    </Grid>
            }
        </Grid>
    )
}

export default KpResult;
