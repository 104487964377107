import React, {useEffect, useState} from 'react';
import {DataProcessingPage, type HowAddDataType} from "../../../common/entering/DataProcessingPage/DataProcessingPage";
import {addHistoryItem, deleteHistoryItem, getHistoryData, historyTypes} from "../../../history/historyProcessing";
import {checkAndGetEstimates} from "../../../../utils/abnDataProcessing";
import {getKpsDataFromFile, savePosterioriInferResult} from "./fileHandling";
import {isNumeric, maxIndexToBinary} from "../../../../utils/numberProcessing";
import FileStructure from "./FileStructure";
import KpsEntering from "./KpsEntering/KpsEntering";
import {KpsType} from '../../../common/displaying/graphs/ParentSeparatorsGraph';
import GlobalPosterioriResult from "./GlobalPosterioriResult";
import {HistoryDataType as HistoryDataPlateType} from "../../../history/HistoryFeed";
import PosterioriHistoryDialogContent from "./PosterioriHistoryDialogContent";
import {EvidenceDataType, EvidenceInputDataType} from "../../../common/entering/EvidenceEntering";
import {paths} from "../../../../config";
import {getDataFromServer} from "../../../../utils/httpUtils";

const HISTORY_TYPE = historyTypes.parentGraphGlobalInfer

type KpsInputType = {
    estimates: string[] | string[][] | undefined,
    maxIndex: number[]
}[]

interface GlobalPosterioriInferResult {
    evidence: EvidenceDataType
    resultKps: KpsType | undefined
    inputKps: KpsType
}

interface GlobalPosterioriInferRequest {
    kps: KpsType
    evidence: EvidenceDataType
}

interface GlobalPosterioriInferResponse extends GlobalPosterioriInferResult {
    errorMessage: string
}

interface HistoryDataItem extends GlobalPosterioriInferResponse {
    date: string
}

type HistoryData = HistoryDataItem[] | never[]


const setPosterioriInferData = (
    globalPosterioriInferData: GlobalPosterioriInferRequest,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: GlobalPosterioriInferResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    getDataFromServer(globalPosterioriInferData, paths.globalPosterioriInfer, response => {
        if (response.errorMessage !== '') {
            onGetErrorMessage(response.errorMessage)
            onGetResultData(undefined)
        } else {
            onGetResultData({
                evidence: response.evidence,
                resultKps: response.resultKps,
                inputKps: response.inputKps,
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        }
    }, error => onGetErrorMessage(error.toString()))
}

const propagate = (
    howAddEstimate: HowAddDataType,
    file: File | undefined,
    kps: KpsInputType,
    evidenceData: EvidenceInputDataType,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: GlobalPosterioriInferResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    if (howAddEstimate === "upload") {
        getKpsDataFromFile(file, result => {
            setPosterioriInferData(
                result,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }, errorMessage => {
            onGetResultData(undefined)
            onGetErrorMessage(errorMessage)
        })
    } else {  // howAddEstimate === "custom"
        const kpsWithNumbers = []
        let newEvidenceData

        if (evidenceData.evidenceType === 'deterministic') {
            // @ts-ignore
            const positiveIndex: number[] = evidenceData.data.positiveIndex
            // @ts-ignore
            const negativeIndex: number[] = evidenceData.data.negativeIndex
            if (positiveIndex.length === 0 && negativeIndex.length === 0) {
                // onGetErrorMessage("Enter some index for deterministic evidence")
                onGetErrorMessage("Enter some index for deterministic evidence")
                onGetResultData(undefined)
                return
            }

            newEvidenceData = {
                evidenceType: evidenceData.evidenceType,
                // @ts-ignore
                data: {positiveIndex, negativeIndex}
            }
        } else {
            // @ts-ignore
            const maxIndex = evidenceData.data.maxIndex
            if (maxIndex.length === 0) {
                onGetErrorMessage("Enter max index for evidence")
                onGetResultData(undefined)
                return
            }

            // @ts-ignore
            const estimates = evidenceData.data.estimates
            const estimatesType = evidenceData.evidenceType === 'stochastic' ? 'scalar' : 'interval'
            const estimatesResult = checkAndGetEstimates(estimates, 'conjuncts', maxIndex, estimatesType)
            if (estimatesResult.errorMessage !== '') {
                onGetErrorMessage("Incorrect estimates for evidence")
                onGetResultData(undefined)
                return
            }

            newEvidenceData = {
                evidenceType: evidenceData.evidenceType,
                // @ts-ignore
                data: {
                    maxIndex: maxIndex,
                    estimates: estimatesResult['estimates']
                }
            }
        }

        for (const kp of kps) {
            if (kp.maxIndex === undefined) {
                onGetErrorMessage("Not all max indices was entered")
                onGetResultData(undefined)
                return
            }

            const estimatesType = (
                kp.estimates && kp.estimates.length > 0 && kp.estimates[0] && isNumeric(kp.estimates[0].toString())
                    ? 'scalar' : 'interval'
            )
            const checkEstimatesResult = checkAndGetEstimates(
                kp.estimates,
                'conjuncts',
                kp.maxIndex,
                estimatesType
            )

            if (checkEstimatesResult['errorMessage'] !== '') {
                onGetResultData(undefined)
                onGetErrorMessage(checkEstimatesResult['errorMessage'])
                return
            }

            kpsWithNumbers.push({
                maxIndex: kp.maxIndex,
                estimates: checkEstimatesResult['estimates']
            })
        }

        setPosterioriInferData(
            {
                // @ts-ignore
                evidence: newEvidenceData,
                // @ts-ignore
                kps: kpsWithNumbers
            },
            onGetErrorMessage,
            onGetResultData,
            onGetNewHistoryItem
        )
    }
}


const PosterioriInfer = () => {
    const [howAddEstimate, setHowAddEstimate] = React.useState<HowAddDataType>('custom');
    const [file, setFile] = useState<File | undefined>(undefined);
    const [historyData, setHistoryData] = React.useState<HistoryData>(getHistoryData(HISTORY_TYPE));
    const [kps, setKps] = React.useState<KpsInputType>([])
    const [errorMessage, setErrorMessage] = useState('')
    const [resultData, setResultData] = React.useState<GlobalPosterioriInferResult | undefined>(undefined);
    const [evidenceData, setEvidenceData] = React.useState<EvidenceInputDataType | undefined>(undefined)
    // @ts-ignore
    const [historyPlateData, setHistoryPlateData] = React.useState<HistoryDataPlateType>([]);

    const onKpsChange = (index: number, newEstimates: string[] | string[][], maxIndex: number[]) => {
        const newKps = [...kps]
        newKps[index] = {
            estimates: newEstimates,
            maxIndex: maxIndex
        }
        setErrorMessage('')
        setKps(newKps)
    }

    const onKpsCountChange = (kpsCount: number) => {
        if (kpsCount < kps.length) {
            setKps([...kps.slice(0, kps.length - 1)])
        }
    }

    const onDeleteHistoryItem = (i: number) => {
        const newHistory = deleteHistoryItem(HISTORY_TYPE, i)
        setHistoryData(newHistory)
    }

    useEffect(() => {
        const content = historyData.map(historyItem => (
            {
                date: historyItem.date,
                // title: "Global a posteriori infer",
                title: "Глобальный апостериорный вывод",
                hasSuccess: true
            }
        ))
        setHistoryPlateData(
            {
                successMessage: 'Свидетельство пропагировано',
                failureMessage: 'Ошибка при пропагации 😞',
                content
            }
        )
    }, [historyData])


    const getDialogHistoryItem = (historyIndex: number) => {
        const historyItem = historyData[historyIndex]
        return (
            <PosterioriHistoryDialogContent
                evidence={historyItem['evidence']}
                inputKps={historyItem['inputKps']}
                // @ts-ignore
                resultKps={historyItem['resultKps']}
            />
        )
    }

    return (
        <DataProcessingPage
            howAddData={howAddEstimate}
            // @ts-ignore
            onAddDataChange={setHowAddEstimate}
            chooseDataEnteringTypeLabel="Как вводить данные?"
            enterCustomDataLabel="Вручную"
            customDataEntering={<KpsEntering onKpsChange={onKpsChange} onEvidenceDataChange={setEvidenceData}
                                             onKpsCountChange={onKpsCountChange}/>}
            fileStructure={<FileStructure/>}
            onFileUpload={setFile}
            postUrl={paths.globalPosterioriInfer}
            onGetResultButtonClick={() => propagate(
                howAddEstimate,
                file,
                kps,
                // @ts-ignore
                evidenceData,
                setErrorMessage,
                setResultData,
                (newItem: any) => {
                    addHistoryItem(HISTORY_TYPE, newItem)
                    setHistoryData(getHistoryData(HISTORY_TYPE))
                }
            )}
            // getResultButtonLabel="Propagate"
            getResultButtonLabel="Распространить влияние свидетельства"
            errorMessage={errorMessage}
            resultComponent={
                resultData
                    ? <GlobalPosterioriResult kps={resultData['resultKps']} evidence={resultData['evidence']}/>
                    : null
            }
            historyData={historyPlateData}
            onDeleteItem={onDeleteHistoryItem}
            getResultItem={getDialogHistoryItem}
            // @ts-ignore
            onSaveButtonClick={() => savePosterioriInferResult(resultData)}
        />
    )
}

export {PosterioriInfer}
export type {GlobalPosterioriInferRequest, KpsInputType, KpsType, GlobalPosterioriInferResult}
