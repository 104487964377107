import {getDataFromFile, saveObjectToFile} from "../../../../utils/fileHandling";
import {AcyclicalityRequest, AcyclicalityResult} from "./AcyclicalityCheck";

const getKpsIndicesFile = (
    currentFile: File | undefined,
    onSuccess: (data: AcyclicalityRequest) => any,
    onError: (errorMessage: string) => any
) => {
    if (currentFile === undefined) {
        // onError("Upload a file")
        onError("Загрузите файл")
    }

    // @ts-ignore
    getDataFromFile(currentFile, (data => {
        for (const field of ['kpsIndices']) {
            if (!data.hasOwnProperty(field)) {
                // onError(`Field '${field}' not found`)
                onError(`Файл '${field}' не найден`)
                return
            }
        }

        // @ts-ignore
        const kpsIndices = data['kpsIndices']

        if (!kpsIndices || !Array.isArray(kpsIndices)) {
            onError(`Indices isn't an array`)
            return
        }

        for (const index of kpsIndices) {
            if (!index.toString().match(/^\+?\d+$/)) {
                // onError(`One of indices isn't an positive integer`)
                onError(`Один из индексов не положителен`)
                return
            }
        }

        onSuccess({kpsIndices})
    }), (errorMessage => {
        onError(errorMessage)
    }))
}

const saveAcyclicalityResult = (result: AcyclicalityResult) => {
    saveObjectToFile(result, "acyclicality_check_result")
}

export {getKpsIndicesFile, saveAcyclicalityResult}
