import React from 'react';
import {Container, Box, Typography, Tabs, Tab} from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel({children, value, index, ...other}: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    {children}
                </Box>
            )}
        </div>
    );
}


interface IPage {
    subtitle?: string
    title: string
    tabData: {
        label: string
        component: React.ReactNode
    }[]
}


const Page = ({title, tabData, subtitle}: IPage) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Container sx={{my: 5, mt: 15}}>
            <Typography textAlign="center" variant="h2" sx={{mb: 2}}>
                {title}
            </Typography>
            {
                subtitle && (
                    <Typography textAlign="center" variant="h5" sx={{mb: 4}} color="#bdbdbd">
                        {subtitle}
                    </Typography>
                )
            }

            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
            >
                {
                    tabData.map((componentData, index) => (
                        <Tab
                            value={index}
                            label={componentData.label}
                            key={componentData.label}
                            sx={{textTransform: 'none', fontSize: 22}}
                        />
                    ))
                }
            </Tabs>
            {
                tabData.map((componentData, index) => (
                    <TabPanel value={tabValue} index={index} key={componentData.label}>
                        {componentData.component}
                    </TabPanel>
                ))
            }
        </Container>
    );
};

export default Page;
