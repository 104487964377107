import React from 'react';
import {Grid, Typography} from "@mui/material";
import {ParentSeparatorsGraph} from "../../../common/displaying/graphs/ParentSeparatorsGraph";

interface IAcyclicalityResult {
    kpsIndices: number[][],
    isAcyclic: boolean
}

const AcyclicalityResult = ({kpsIndices, isAcyclic}: IAcyclicalityResult) => {
    // const text = isAcyclic ? 'This graph is acyclic!' : "This graph isn't acyclic"
    const text = isAcyclic ? 'АБС ациклична!' : "АБС не ациклична 😞"
    const color = isAcyclic ? '#81c784' : '#e57373'
    return (
        <Grid container justifyContent="center" alignItems="center" width={"100%"} sx={{mt: 2, mb: 4}}>
            <Grid item sx={{my: 2}}>
                {/*<Typography variant="h3" textAlign="center">Result</Typography>*/}
                <Typography variant="h3" textAlign="center">Результат</Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" width={"100%"}>
                <Grid item>
                    <ParentSeparatorsGraph kps={kpsIndices.map(i => ({maxIndex: i}))}/>
                </Grid>
            </Grid>
            <Grid item sx={{my: 2}}>
                <Typography variant="h5" textAlign="center" color={color}>{text}</Typography>
            </Grid>
        </Grid>
    );
};

export {AcyclicalityResult};
