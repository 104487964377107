import React from 'react';
import {countUnitBits} from "../../../../utils/numberProcessing";
import {Box} from "@mui/material";
import {Arrow, Canvas, Edge, Node} from "reaflow";
import {subIdealIndexToIdeal} from "../../../../utils/abnDataProcessing";
import { bitsToAtoms } from './utils';

const round = (number: number, precision: number = 5) => {
    const additional_multiplier = Math.pow(10, precision)
    return Math.round(number * additional_multiplier) / additional_multiplier
}

function getKPData(maxIndex: number[], estimates: number[] | number[][] | undefined, isDisjuncts?: boolean) {
    const nodes = []
    const edges = []
    const conjunctsCount = Math.pow(2, maxIndex.length)
    for (let i = 0; i < conjunctsCount; i++) {
        // @ts-ignore
        let text
        let currentEstimates = ''
        if (estimates !== undefined) {
            // @ts-ignore
            currentEstimates = typeof estimates[i] === 'number' ? ` = ${round(estimates[i]).toString()}` : ` = [${estimates[i].map(e => round(e)).join(', ')}]`
            text = `p(${bitsToAtoms(subIdealIndexToIdeal(i, maxIndex), isDisjuncts)})${currentEstimates}`
        } else {
            text = `${bitsToAtoms(subIdealIndexToIdeal(i, maxIndex), isDisjuncts)}`
        }
        nodes.push({
            id: (i).toString(),
            text: text,
            ports: [
                {
                    id: `${i}-to`,
                    width: 0,
                    height: 0,
                    side: "SOUTH"
                },
                {
                    id: `${i}-from`,
                    width: 0,
                    height: 0,
                    side: "NORTH"
                }
            ]
        })
    }
    for (let i = 0; i < conjunctsCount; i++) {
        for (let j = i + 1; j < conjunctsCount; j++) {
            // @ts-ignore
            if ((countUnitBits(i) === countUnitBits(j) - 1) && ((i & j) === i)) {
                edges.push({
                    id: `${i}-${j}`,
                    from: (i).toString(),
                    to: (j).toString(),
                    fromPort: `${i}-from`,
                    toPort: `${j}-to`,
                })
            }
        }
    }
    return {nodes, edges}
}

interface IKnowledgePattern {
    maxIndex: number[]
    estimates: number[] | number[][] | undefined
    isDisjuncts?: boolean
}

const ifEdgeStroke = (edgeId: string) => {
    const [nodeId1, nodeId2] = edgeId.split('-')
    return Number(nodeId1) === 0 || Number(nodeId2) === 0
}

const getOffset = (nodeId: string) => {
    const nodeIdNumber = Number(nodeId)
    const unitBitsCount = countUnitBits(nodeIdNumber)
    return Number(unitBitsCount) * 2
}

const KnowledgePattern = ({maxIndex, estimates, isDisjuncts}: IKnowledgePattern) => {
    const kpGraphHeight = maxIndex.length * 190
    const kp_data = getKPData(maxIndex, estimates, isDisjuncts)
    return (
        <Box>
            <Canvas
                arrow={<Arrow/>}
                direction={"UP"}
                maxHeight={kpGraphHeight}
                maxWidth={1000}
                // @ts-ignore*/
                nodes={kp_data['nodes']}
                // @ts-ignore*/
                edges={kp_data['edges']}
                edge={(edge) => (
                    <Edge {...edge}
                          selectable={false}
                          style={{strokeDasharray: ifEdgeStroke(edge.id) ? 5 : undefined}}
                    />)}
                node={node => {
                    return <Node
                        selectable={false}
                        offsetX={-getOffset(node.id)}
                    />
                }}
            />
        </Box>
    )
}

export default KnowledgePattern;
