import axios from "axios";

const getDataFromServer = (
    requestData: any,
    url: string,
    onSuccess: (responseData: any) => any,
    onError: (error: any) => any
) => {
    axios({
        method: 'post',
        url: url,
        data: {...requestData},
        xsrfCookieName: 'csrftoken',
        xsrfHeaderName: 'X-CSRFTOKEN',
        withCredentials: true
    })
        // @ts-ignore
        .then(function (response: { data: any }) {
            onSuccess(response.data)
        })
        .catch(function (error: any) {
            onError(error)
        })
}

export {getDataFromServer}