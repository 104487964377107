import React from 'react';
import {OneEstimateEntering, EstimatesText} from "./common";
import {Grid} from "@mui/material";
// @ts-ignore
import {InlineMath} from 'react-katex';

interface IEstimate {
    text: string
    type: 'scalar' | 'interval',
    sx?: any
    onLeftChange: (newValue: any) => any
    onRightChange?: (newValue: any) => any
    leftValue: string
    rightValue?: string
    isClearInput?: boolean
}

const Estimate = ({text, type, sx, onLeftChange, onRightChange, leftValue, rightValue, isClearInput}: IEstimate) => {
    return (
        <Grid container direction="row" sx={{...sx, width: 'max-content'}}>
            <OneEstimateEntering isLeft={true} onChange={onLeftChange} value={leftValue} isClearInput={isClearInput}/>
            <EstimatesText sx={{borderRadius: type === 'scalar' ? '0px 5px 5px 0px' : undefined}}>
                <InlineMath math={text.toString()}/>
            </EstimatesText>
            {
                // @ts-ignore
                type === 'interval' ? <OneEstimateEntering onChange={onRightChange} value={rightValue} isClearInput={isClearInput}/> : null
            }

        </Grid>
    );
};

export default Estimate;
