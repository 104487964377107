import React, {useCallback, useEffect} from 'react';
import {getNewEstimates, KPEntering} from "../../../../common/entering/KPEntering";
import {EstimatesType, MetaKPDataEntering} from "../../../../common/entering/MetaKPDataEntering";
import {Box} from "@mui/material";

const KP_TYPE = "conjuncts"
const INITIAL_ESTIMATES_TYPE = "scalar"
const INITIAL_MAX_INDEX = [0]

interface IKpEntering {
    onDataChange: (newEstimates: string[] | string[][], maxIndex: number[]) => any
}

const KpEntering = ({onDataChange}: IKpEntering) => {
    const [maxIndex, setMaxIndex] = React.useState<number[]>([0])
    const [estimates, setEstimates] = React.useState<string[] | string[][] | undefined>(getNewEstimates(KP_TYPE, INITIAL_MAX_INDEX))
    const [estimatesType, setEstimatesType] = React.useState<EstimatesType>(INITIAL_ESTIMATES_TYPE)
    const [isClearInput, setIsClearInput] = React.useState(true)

    const onEstimatesChange = useCallback((newEstimates: string[] | string[][]) => {
        setIsClearInput(false)
        setEstimates(newEstimates)
        onDataChange(newEstimates, maxIndex)
    }, [maxIndex, onDataChange])

    useEffect(() => {
        setIsClearInput(true)
        const newEstimates = getNewEstimates(KP_TYPE, maxIndex)
        setEstimates(newEstimates)
        onDataChange(newEstimates, maxIndex)
    }, [estimatesType, maxIndex])

    // @ts-ignore
    return (
        <Box>
            <MetaKPDataEntering
                maxIndexValue={maxIndex}
                kpTypeValue='conjuncts'
                estimateTypeValue={estimatesType}
                onMaxIndexChange={setMaxIndex}
                onChangeEstimatesType={setEstimatesType}
                needChooseKPType={false}
            />

            <Box sx={{my: 5}}>
                <KPEntering maxIndex={maxIndex}
                    // @ts-ignore
                            onEstimatesChange={onEstimatesChange}
                            estimates={estimates}
                            isClearInput={isClearInput}
                    // @ts-ignore
                            type={estimatesType}
                            kpType="conjuncts"/>
            </Box>
        </Box>
    )
}

export default KpEntering;
