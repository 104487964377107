import React from 'react';
import {commentColor, keyColor, valueColor} from "../../../common/displaying/FileStructure";
import {Typography, TypographyProps} from "@mui/material";

const T = (props: TypographyProps) => <Typography {...props}/>

const FileStructure = () => {
    return (
        <>
            <T color={valueColor}>{'{'}</T>
            <T sx={{ml: 3}}><T color={keyColor} display="inline">{'"kpsAtoms"'}</T> <T display="inline">: [</T></T>
            <T sx={{ml: 6}}><T display="inline">[number, ...],</T> <T color={commentColor} display="inline">&nbsp;&nbsp;{'//  Индексы атомов для одного ФЗ'}</T></T>
            <T sx={{ml: 6}}><T display="inline">[number, ...],</T></T>
            <T sx={{ml: 6}}><T display="inline">...</T></T>
            <T sx={{ml: 3}}><T display="inline">]</T></T>
            <T color={valueColor}>{'}'}</T>
        </>
    );
};

export default FileStructure;
