import React from 'react';
import Page from "../Page";
import 'katex/dist/katex.min.css';
import {Reconciliation} from "./Reconciliation/Reconciliation";

const KpPage = () => {
    return (
        // <Page title="Knowledge pattern" tabData={[
        //     {
        //         label: 'Reconciliation',
        //         component: <Reconciliation />
        //     }
        // ]} />
    <Page title="Модель фрагмента знаний" tabData={[
            {
                label: 'Непротиворечивость',
                component: <Reconciliation />
            }
        ]} />
    );
};

export default KpPage;
