const paths = {
    main: '/',
    parentSeparatorsGraph: '/parent_separators_graph',
    primaryStructure: '/primary_structure',
    getReconciliation: '/get_reconciliation',
    checkAcyclicality: '/check_acyclicality',
    globalPosterioriInfer: '/global_posteriori_infer',
    training_representation: '/training',
    training_algorithm: '/training_representation',
}

export {paths}
