import React from 'react';
import Page from "../Page";
import {PosterioriInfer} from "./Posteriori/PosterioriInfer";


const ParentSeparatorsGraphPage = () => {
    return (
        // <Page title="Parent separators graph" subtitle="Algorithms that use the parent separators graph" tabData={[
        //     {
        //         label: 'A posteriori infer',
        //         component: <PosterioriInfer />
        //     },
        // ]}/>
        <Page title="Третичная структура" tabData={[
            {
                label: 'Апостериорный вывод',
                component: <PosterioriInfer />
            },
        ]} />
    );
};

export default ParentSeparatorsGraphPage;
