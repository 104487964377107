import React from 'react';
import {keyColor, valueColor} from "../../../common/displaying/FileStructure";
import {Typography, TypographyProps} from "@mui/material";

const T = (props: TypographyProps) => <Typography {...props}/>

const FileStructure = () => {
    return (
        <>
            <T color={valueColor}>{'{'}</T>
            <T sx={{ml: 3}}><T color={keyColor} display="inline">{'"kps"'}</T> <T display="inline">{': [{'}</T></T>
            <T sx={{ml: 6}}><T color={keyColor} display="inline">{'"maxIndex"'}</T> <T display="inline">: [number, ...]</T></T>
            <T sx={{ml: 6}}><T color={keyColor} display="inline">{'"estimates"'}</T> <T display="inline">: [number or [number, number], ...]</T></T>
            <T sx={{ml: 3}}>{'}, ... ],'}</T>
            <T sx={{ml: 3}}><T color={keyColor} display="inline">{'"evidence"'}</T> <T display="inline">{': {'}</T></T>
            <T sx={{ml: 6}}><T color={keyColor} display="inline">{'"type"'}</T> <T display="inline">: string ("deterministic" or "imprecise" or "stochastic"),</T></T>
            <T sx={{ml: 6}}><T color={keyColor} display="inline">{'"data"'}</T> <T display="inline">{': {'}</T></T>
            <T sx={{ml: 9}}><T color={keyColor} display="inline">{'"positiveIndex"'}</T> <T display="inline">: [number, ...]</T></T>
            <T sx={{ml: 9}}><T color={keyColor} display="inline">{'"negativeIndex"'}</T> <T display="inline">: [number, ...]</T></T>
            <T sx={{ml: 6}}><T display="inline">{'} or {'}</T></T>
            <T sx={{ml: 9}}><T color={keyColor} display="inline">{'"maxIndex"'}</T> <T display="inline">: [number, ...]</T></T>
            <T sx={{ml: 9}}><T color={keyColor} display="inline">{'"estimates"'}</T> <T display="inline">[number or [number, number], ...]</T></T>
            <T sx={{ml: 6}}><T display="inline">{'}'}</T></T>
            <T sx={{ml: 3}}><T display="inline">{'}'}</T></T>
            <T color={valueColor}>{'}'}</T>
        </>
    );
};

export default FileStructure;
