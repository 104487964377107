import {getDataFromFile, saveObjectToFile} from "../../../../utils/fileHandling";
import {checkAndGetEstimates} from "../../../../utils/abnDataProcessing";
import {ReconciliationResult} from "./Reconciliation";

const getKpDataFromFile = (
    currentFile: File | undefined,
    onSuccess: (data: object) => any,
    onError: (errorMessage: string) => any
) => {
    if (currentFile === undefined) {
        onError("Upload a file")
    }

    // @ts-ignore
    getDataFromFile(currentFile, (data => {
        for (const field of ['kpType', 'estimates', 'maxIndex']) {
            // @ts-ignore
            if (!data.hasOwnProperty(field)) {
                onError(`Field '${field}' not found`)
                return
            }
        }

        // @ts-ignore
        const kpType = data['kpType']
        // @ts-ignore
        const maxIndex = data['maxIndex']
        // @ts-ignore
        let estimates = data['estimates']

        if (kpType !== 'disjuncts' &&
            kpType !== 'quants' &&
            kpType !== 'conjuncts') {
            onError(`Incorrect kp type`)
            return
        }

        if (isNaN(maxIndex)) {
            onError(`Incorrect max index`)
            return
        }

        // @ts-ignore
        if (!Array.isArray(estimates) || estimates.length === 0) {
            onError(`Incorrect estimates`)
            return
        }
        const estimatesType = Array.isArray(estimates[0]) ? 'interval' : 'scalar'
        const estimatesResult = checkAndGetEstimates(estimates, kpType, maxIndex, estimatesType)
        if (estimatesResult.errorMessage !== '') {
            onError(`Incorrect estimates`)
            return
        }

        onSuccess({
            kpType: kpType,
            estimates: estimates,
            maxIndex: maxIndex
        })
    }), (errorMessage => {
        onError(errorMessage)
    }))
}

const saveReconciliationKpResult = (result: ReconciliationResult) => {
    saveObjectToFile(result, result.kpType)
}

export {getKpDataFromFile, saveReconciliationKpResult}
