import React from 'react';
import Page from "../Page";
import {AcyclicalityCheck} from "./Acyclicality/AcyclicalityCheck";

const PrimaryStructure = () => {
    return (
        // <Page title="Primary structure" tabData={[
        //     {
        //         label: 'Acyclicality',
        //         component: <AcyclicalityCheck />
        //     },
        // ]}/>
        <Page title="Первичная структура" tabData={[
            {
                label: 'Ацикличность',
                component: <AcyclicalityCheck />
            },
        ]}/>
    );
};

export default PrimaryStructure;
