import React from 'react';
import {alpha, styled} from "@mui/material/styles";
import {Button, ButtonProps, Typography} from "@mui/material";

const StyledBigButton = styled(Button)<ButtonProps>(() => {
    return {
        '&.MuiButton-root': {
            textTransform: 'none !important'
        },
    }
})

interface IBigButton {
    onClick: () => any
    label: string
}

const BigButton = ({onClick, label}: IBigButton) => {
    return (
        <StyledBigButton sx={{border: 1, borderColor: alpha('#ad1457', 0.2), minWidth: 150, height: 50, paddingX: 2}}
                   onClick={onClick}>
            <Typography>
                {label}
            </Typography>
        </StyledBigButton>
    )
}

export default BigButton;
