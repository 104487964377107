import React from 'react';
import {Grid, Typography} from "@mui/material";
import {KpType} from "../../../common/entering/MetaKPDataEntering";
import KpResult from "../../../common/displaying/KpResult";

interface IConsistencyCheckResult {
    estimates?: any
    isConsistent: boolean
    maxKPIndex?: number[]
    kpType: KpType
}

const ConsistencyCheckResult = ({
                                    estimates,
                                    maxKPIndex,
                                    isConsistent,
                                    kpType
                                }: IConsistencyCheckResult) => {
    return (
        <Grid>
            <Grid item>
                <Typography variant="h3" textAlign="center">
                    Результат
                </Typography>
            </Grid>
            <Grid item container justifyContent="center" alignItems="center" width={"100%"}>
                {
                    !isConsistent
                        ?
                        // <Typography color="#e53935" variant="h4" textAlign="center" sx={{my: 2}}>
                        //     Knowledge pattern is inconsistent!
                        // </Typography>
                        <Typography color="#e53935" variant="h4" textAlign="center" sx={{my: 2}}>
                            ФЗ противоречив 😞
                        </Typography>
                        :
                        <>
                            <Typography color="#81c784" variant="h5" textAlign="center" sx={{my: 2}}>
                                ФЗ непротиворечив!
                            </Typography>
                            <KpResult
                                kpType={kpType}
                                estimates={estimates}
                                // @ts-ignore
                                maxKPIndex={maxKPIndex}/>
                        </>
                }
            </Grid>
        </Grid>
    )
}

export {ConsistencyCheckResult};
