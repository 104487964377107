import React from 'react';
import {maxIndexToBinary, numberToBinary} from "../../../../utils/numberProcessing";
import {Grid, Typography} from "@mui/material";
import TripleCheckbox from "../../../common/entering/TripleCheckbox";
// @ts-ignore
import {InlineMath} from 'react-katex';

interface ITrainingDataEntering {
    onQuantChange: (quantIndex: number, atomIndex: number, newValue: boolean | undefined) => void
    quantsCount: number | undefined
    maxIndex: number[]
    isClearInput?: boolean
}

const getAtoms = (maxIndex: number[]) => {
    const bitsString = maxIndexToBinary(maxIndex).split("").reverse().join("")
    console.log('bs', bitsString)
    const result = []
    for (let i = bitsString.length - 1; i >= 0; i--) {
        if (bitsString[i] === "1") {
            result.push(`x_{${i}}`)
        }
    }
    return result
}

function TrainingDataEntering({onQuantChange, quantsCount, maxIndex, isClearInput}: ITrainingDataEntering) {
    console.log(maxIndex)
    const atoms = maxIndex !== undefined ? getAtoms(maxIndex).reverse() : []
    console.log(atoms)
    const arrayToMapQuantsCount = new Array(quantsCount).fill(0)
    console.log(atoms)
    console.log('')
    return (
        <Grid container>
            {
                atoms.map((atom) =>
                    <Grid width={70} mb={1}>
                        <Typography style={{fontSize: 30}} textAlign="center">
                            <InlineMath math={atom.toString()}/>
                        </Typography>
                    </Grid>
                )
            }
            {
                arrayToMapQuantsCount.map((_, quantIndex) => {
                    return <Grid container>
                        {
                            atoms.map((atom, atomIndex) => (
                                <Grid width={70} justifyContent='center' container mb={1.5}>
                                    <Grid>
                                        <TripleCheckbox
                                            isClearInput={isClearInput}
                                            onCheckboxChange={newValue => onQuantChange(quantIndex, atomIndex, newValue)}
                                            key={atom}/>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                })
            }
        </Grid>
    );
}

export default TrainingDataEntering;