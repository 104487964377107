import React, {FC, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Drawer, IconButton, SvgIcon, ToggleButtonProps} from "@mui/material";
import Grid from "@mui/material/Grid";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {alpha, styled} from '@mui/material/styles';
import {ReactComponent as Logo} from "../static/logo.svg";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {paths} from "../config";

const SuccessToggleButton = styled(ToggleButton)<ToggleButtonProps>(({theme}) => ({
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    borderWidth: 0,
    borderRadius: 0,
    transition: theme.transitions.create(['background-color'], {duration: theme.transitions.duration.standard}),
    // },
    '&.MuiToggleButton-root': {
        '&:hover, &.Mui-focusVisible': {
            backgroundColor: alpha(theme.palette.primary.main, 0.4),
            color: theme.palette.primary.contrastText,
            borderWidth: 0
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            borderWidth: 0,
            '&:hover, &.Mui-focusVisible': {
                backgroundColor: alpha(theme.palette.primary.main, 0.4),
                color: theme.palette.primary.contrastText,
                borderWidth: 0
            },
        },
    },
}))

interface IAppBarButton {
    title: string
}

const AppBarToggleButton: FC<IAppBarButton & ToggleButtonProps> = ({title, ...others}) => {
    return (
        <SuccessToggleButton {...others} color='primary' sx={{px: 3}}>
            <Typography textTransform="none" fontSize={18} letterSpacing={0.8}>
                {title}
            </Typography>
        </SuccessToggleButton>
    )
}

const Header: FC = () => {
    let currentLocation = window.location.pathname;
    const [alignment, setAlignment] = React.useState(currentLocation);
    const hasDropDownMenu = window.innerWidth < 750;

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        handleNewAlignment(newAlignment)
    };

    const handleNewAlignment = (newAlignment: string) => {
        if (newAlignment !== null) {
            window.location.assign(newAlignment)
            setAlignment(newAlignment)
        }
    }

    const [drawerState, setDrawerState] = useState<boolean>(false)

    const texts = [
        {
            value: paths.main,
            title: 'Модель фрагмента знаний'
        },
        {
            value: paths.primaryStructure,
            title: 'Первичная структура'
        },
        {
            value: paths.parentSeparatorsGraph,
            title: 'Третичная структура'
        },
        {
            value: paths.training_representation,
            title: 'Обучение'
        }
    ]

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawerState(open);
        };
    console.log(currentLocation)

    const list = () => (
        <Box
            sx={{width: 300}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {texts.map(({value, title}, index) => (
                    <ListItem key={value} disablePadding>
                        <ListItemButton onClick={() => handleNewAlignment(value)}
                                        sx={{backgroundColor: currentLocation === value ? '#ad1457' : undefined}}
                        >
                            <ListItemText
                                primary={<Typography style={{ color: currentLocation === value ? 'white' : undefined }}>{title}</Typography>}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const OnWideScreen = () => (
        <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            sx={{height: '100%'}}
        >
            <Grid container justifyContent="center" alignItems="center" width={'max-content'}
                  onClick={() => handleNewAlignment("/")}>
                <Grid item width={'max-content'}>
                    <SvgIcon viewBox="0 0 354 131" sx={{width: 180, transform: "scale(2.1)"}}>
                        <Logo/>
                    </SvgIcon>
                </Grid>
            </Grid>

            <AppBarToggleButton value={paths.main} title="Модель фрагмента знаний"/>
            <AppBarToggleButton value={paths.primaryStructure} title="Первичная структура"/>
            <AppBarToggleButton value={paths.parentSeparatorsGraph} title="Третичная структура"/>
            <AppBarToggleButton value={paths.training_representation} title="Обучение"/>
        </ToggleButtonGroup>
    )

    const OnThinScreen = () => <>
        <Grid container direction="row" justifyContent="space-between" sx={{height: '100%'}}>
            <Grid item sx={{height: '100%'}}>
                <Grid container justifyContent="center" alignItems="center" width={'max-content'} height={'100%'}
                      onClick={() => handleNewAlignment("/")}>
                    <Grid item width={'max-content'}>
                        <SvgIcon viewBox="0 0 354 131" sx={{width: 180, transform: "scale(2.1)"}}>
                            <Logo/>
                        </SvgIcon>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <>
                    <IconButton
                        sx={{height: '100%', mr: 2}}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuOpenIcon fontSize='large' sx={{transform: "scale(1.5)"}}/>
                    </IconButton>
                    <Drawer
                        anchor='right'
                        open={drawerState}
                        onClose={toggleDrawer(false)}
                    >
                        {list()}
                    </Drawer>
                </>
            </Grid>
        </Grid>
    </>


    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar color='secondary' sx={{width: '100%', height: 80, boxShadow: '0px 0px 12px #bdbdbd'}}
                    position="fixed">
                {
                    hasDropDownMenu ? <OnThinScreen/> : <OnWideScreen/>
                }
            </AppBar>
        </Box>
    );
};

export default Header;
