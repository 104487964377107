import { isNumeric, numberToBinary } from "./numberProcessing";
import { EstimatesType, KpType } from "../components/common/entering/MetaKPDataEntering";

const str2arrayQuants = (estimates: any[]) => {
    var n = estimates[0].length, m = estimates.length;
    var quantsArr = Array(m).fill(undefined).map(() => Array(n));
    const getOnlyErrorMessage = (errorMessage: string) => ({ estimates: undefined, errorMessage })
    for (var i = 0; i < m; i++) {
        for (var k = 0; k < n; k++) {
            if (estimates[i][k] === '1') {
                quantsArr[i][k] = true
            }
            else if (estimates[i][k] === '0') {
                quantsArr[i][k] = false
            }
            else if (estimates[i][k] === '*') {
                quantsArr[i][k] = undefined
            }
            else {
                // return getOnlyErrorMessage('Please, use only allowed symbols')
                return getOnlyErrorMessage('Пожалуйста, используйте разрешенные символы')
            }
        }
    }
    return quantsArr
}

const checkAndGetQuants = (
    estimates: string[] | string[][] | undefined,
    estimatesType: EstimatesType,

) => {
    const getOnlyErrorMessage = (errorMessage: string) => ({ estimates: undefined, errorMessage })

    if (estimates === undefined) {
        // return getOnlyErrorMessage('Please, enter all quants')
        return getOnlyErrorMessage('Пожалуйста, введите все кванты')
    }

    const expectedLength = estimates.length
    for (let i = 0; i < expectedLength; i++) {
        if (estimates === undefined || !estimates[i]) {
            // return getOnlyErrorMessage('Please, enter all quants')
            return getOnlyErrorMessage('Пожалуйста, введите все кванты')
        }
    }
    return {
        errorMessage: '',
        // @ts-ignore
        estimates: str2arrayQuants(estimates)
    }
}

const str2arrayQuantsOld = (estimates: any[]) => {
    var n = estimates[0].length, m = estimates.length;
    var quantsArr = [];
    for (var i = 0; i < m; i++) {
        quantsArr[i] = estimates[i].split('');
    }
    return quantsArr
}

const checkAndGetQuantsOld = (
    estimates: string[] | string[][] | undefined,
    quantsCount: number,
    estimatesType: EstimatesType
) => {
    const expectedLength = quantsCount
    const getOnlyErrorMessage = (errorMessage: string) => ({ estimates: undefined, errorMessage })
    for (let i = 0; i < expectedLength; i++) {
        if (estimates === undefined || !estimates[i]) {
            // return getOnlyErrorMessage('Please, enter all estimates')
            return getOnlyErrorMessage('Пожалуйста, введите все оценки')
        }
    }
    return {
        errorMessage: '',
        // @ts-ignore
        estimates: estimates
    }
}

function subIdealIndexToIdeal(subIdealIndex: number, maxIdealIndex: number) {
    if (subIdealIndex === 0 && maxIdealIndex === 0) {
        return "0"
    }

    let idealIndex = ""
    const binarySubIdealIndex = numberToBinary(subIdealIndex)
    const binaryMaxIdealIndex = numberToBinary(maxIdealIndex)
    let currentIdealBit = binaryMaxIdealIndex.length - 1
    for (let i = binarySubIdealIndex.length - 1; i >= 0; i--) {
        while (binaryMaxIdealIndex[currentIdealBit] === "0") {
            currentIdealBit--
            idealIndex = `0${idealIndex}`
        }
        currentIdealBit--
        idealIndex = `${binarySubIdealIndex[i]}${idealIndex}`
    }
    return idealIndex
}

export { str2arrayQuants, checkAndGetQuants, subIdealIndexToIdeal, str2arrayQuantsOld, checkAndGetQuantsOld }
