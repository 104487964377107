import React, {useEffect, useState} from 'react';
import {type KpType, EstimatesType} from "../../../common/entering/MetaKPDataEntering";
import {ConsistencyCheckResult} from "./ConsistencyCheckResult";
import {getNewEstimates} from "../../../common/entering/KPEntering";
import {addHistoryItem, deleteHistoryItem, getHistoryData, historyTypes} from "../../../history/historyProcessing";
import {DataProcessingPage, type HowAddDataType} from "../../../common/entering/DataProcessingPage/DataProcessingPage";
import CustomDataEntering from "./CustomDataEntering";
import {type HistoryDataType as HistoryDataPlateType} from "../../../history/HistoryFeed";
import {capitalizeFirstLetter} from "../../../../utils/stringProcessing";
import ConsistencyHistoryDialogContent from "./ConsistencyHistoryDialogContent";
import {checkAndGetEstimates} from "../../../../utils/abnDataProcessing";
import {FileStructure} from "../../../common/displaying/FileStructure";
import {getKpDataFromFile, saveReconciliationKpResult} from "./fileHandling";
import {paths} from "../../../../config";
import { getDataFromServer } from '../../../../utils/httpUtils';
import {maxIndexToBinary} from "../../../../utils/numberProcessing";

const INITIAL_KP_TYPE = 'conjuncts'
const INITIAL_ESTIMATES_TYPE = 'scalar'
const INITIAL_MAX_INDEX = [0]
const HISTORY_TYPE = historyTypes.kpReconciliation

interface ReconciliationRequest {
    estimates: number[] | number[][],
    kpType: KpType,
    maxIndex: number[]
}

interface ReconciliationResult {
    kpType: KpType,
    inputEstimates: number[] | number[][],
    narrowedEstimates: number[] | number[][] | undefined,
    maxIndex: number[],
    isConsistent: boolean,
}

interface ReconciliationResponse extends ReconciliationResult {
    errorMessage: string
}

interface HistoryDataItem extends ReconciliationResponse {
    date: string,
}

type HistoryData = HistoryDataItem[] | never[]


const setReconciliationData = (
    reconciliationData: ReconciliationRequest,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: ReconciliationResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    getDataFromServer(reconciliationData, paths.getReconciliation, (response) => {
        if (response.errorMessage !== '') {
            onGetErrorMessage(response.errorMessage)
            onGetResultData(undefined)
        } else if (response.isConsistent) {
            onGetResultData({
                isConsistent: true,
                // @ts-ignore
                narrowedEstimates: response.narrowedEstimates,
                inputEstimates: response.inputEstimates,
                // @ts-ignore
                maxIndex: response.maxIndex,
                // @ts-ignore
                kpType: response.kpType
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        } else {
            onGetResultData({
                isConsistent: false,
                narrowedEstimates: undefined,
                inputEstimates: response.inputEstimates,
                // @ts-ignore
                maxIndex: response.maxIndex,
                // @ts-ignore
                kpType: response.kpType
            })
            onGetNewHistoryItem(response)
            onGetErrorMessage('')
        }
    }, (error: any) => onGetErrorMessage(error.toString()))
}

const reconcile = (
    howAddEstimate: HowAddDataType,
    file: File | undefined,
    kpType: KpType,
    estimates: string[] | string[][] | undefined,
    maxIndex: number[],
    estimatesType: EstimatesType,
    onGetErrorMessage: (errorMessage: string) => any,
    onGetResultData: (data: ReconciliationResult | undefined) => any,
    onGetNewHistoryItem: (historyItem: any) => any
) => {
    if (howAddEstimate === "upload") {
        getKpDataFromFile(file, result => {
            setReconciliationData(
                // @ts-ignore
                result,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }, errorMessage => {
            onGetResultData(undefined)
            onGetErrorMessage(errorMessage)
        })
    } else {  // howAddEstimate === "custom"
        // @ts-ignore
        if (maxIndex.length == 0) {
            onGetErrorMessage("Enter max index")
            onGetResultData(undefined)
            return
        }
        const result = checkAndGetEstimates(estimates, kpType, maxIndex, estimatesType)
        if (result['errorMessage'] !== '') {
            onGetResultData(undefined)
            onGetErrorMessage(result['errorMessage'])
        } else {
            const reconciliationData = {
                // @ts-ignore
                estimates: result['estimates'],
                kpType: kpType,
                // @ts-ignore
                maxIndex: maxIndex
            }
            setReconciliationData(
                // @ts-ignore
                reconciliationData,
                onGetErrorMessage,
                onGetResultData,
                onGetNewHistoryItem
            )
        }
    }
}

const Reconciliation = () => {
    const [howAddEstimate, setHowAddEstimate] = React.useState<HowAddDataType>('custom');
    const [estimates, setEstimates] = React.useState<string[] | string[][] | undefined>(getNewEstimates(INITIAL_KP_TYPE, INITIAL_MAX_INDEX))
    const [kpType, setKpType] = React.useState<KpType>(INITIAL_KP_TYPE)
    const [maxIndex, setMaxIndex] = React.useState<number[]>(INITIAL_MAX_INDEX)
    const [estimatesType, setEstimatesType] = React.useState<EstimatesType>(INITIAL_ESTIMATES_TYPE)
    const [isClearInput, setIsClearInput] = React.useState(true)
    const [resultData, setResultData] = React.useState<ReconciliationResult | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState('')
    const [file, setFile] = useState<File | undefined>(undefined);
    // @ts-ignore
    const [historyPlateData, setHistoryPlateData] = React.useState<HistoryDataPlateType>([]);
    const [historyData, setHistoryData] = React.useState<HistoryData>(getHistoryData(HISTORY_TYPE));

    useEffect(() => {
        setErrorMessage('')
    }, [estimates, estimatesType, maxIndex, kpType, howAddEstimate])

    useEffect(() => {
        setIsClearInput(true)
    }, [estimatesType, maxIndex, kpType, howAddEstimate])

    useEffect(() => {
        setIsClearInput(false)
    }, [estimates])

    useEffect(() => {

        const content = historyData.map(historyItem => {
            let title;
            switch (historyItem.kpType) {
                case "conjuncts":
                    title = 'Конъюнкты';
                    break;
                case "disjuncts":
                    title = 'Дизъюнкты';
                    break;
                case "quants":
                    title = 'Кванты';
                    break;
            }
            return {
                date: historyItem.date,
                title: title,
                hasSuccess: historyItem.isConsistent
            }
        })
        setHistoryPlateData(
            {
                // successMessage: 'Consistent',
                // failureMessage: 'Inconsistent',
                successMessage: 'Непротиворечив',
                failureMessage: 'Противоречив',
                content
            }
        )
    }, [historyData])

    const onDeleteHistoryItem = (i: number) => {
        const newHistory = deleteHistoryItem(HISTORY_TYPE, i)
        setHistoryData(newHistory)
    }

    const getDialogHistoryItem = (historyIndex: number) => {
        const historyItem = historyData[historyIndex]
        return <ConsistencyHistoryDialogContent
            kpType={historyItem['kpType']}
            inputEstimates={historyItem['inputEstimates']}
            narrowedEstimates={historyItem['narrowedEstimates']}
            isConsistent={historyItem['isConsistent']}
            maxIndex={historyItem['maxIndex']}
        />
    }


    return (
        <DataProcessingPage
            howAddData={howAddEstimate}
            // @ts-ignore
            onAddDataChange={setHowAddEstimate}
            // chooseDataEnteringTypeLabel="How to add estimates?"
            // enterCustomDataLabel="Enter estimates"
            chooseDataEnteringTypeLabel="Как вводить данные?"
            enterCustomDataLabel="Вручную"
            customDataEntering={
                <CustomDataEntering
                    kpType={kpType}
                    estimatesType={estimatesType}
                    maxIndex={maxIndex}
                    estimates={estimates}
                    isClearInput={isClearInput}
                    onMaxIndexChange={setMaxIndex}
                    onChangeKPType={setKpType}
                    onChangeEstimatesType={setEstimatesType}
                    onEstimatesChange={setEstimates}/>
            }
            fileStructure={
                <FileStructure fileStructureData={[
                    {key: "kpType", value: "string (\"conjuncts\" or \"disjuncts\" or \"quants\"),"},
                    {key: "maxIndex", value: "[number, ...]"},
                    {key: "estimates", value: "[number or [number, number], ...]"},
                ]}/>
            }
            onFileUpload={setFile}
            postUrl={paths.getReconciliation}
            onGetResultButtonClick={() => reconcile(
                howAddEstimate,
                file,
                kpType,
                estimates,
                maxIndex,
                estimatesType,
                setErrorMessage,
                setResultData,
                (newItem: any) => {
                    addHistoryItem(HISTORY_TYPE, newItem)
                    setHistoryData(getHistoryData(HISTORY_TYPE))
                }
            )}
            // getResultButtonLabel="Reconcile"
            getResultButtonLabel="Согласовать оценки"
            errorMessage={errorMessage}
            resultComponent={
                resultData
                    ? <ConsistencyCheckResult
                        // @ts-ignore
                        kpType={resultData['kpType']}
                        // @ts-ignore
                        estimates={resultData['narrowedEstimates']}
                        // @ts-ignore
                        maxKPIndex={resultData['maxIndex']}
                        isConsistent={resultData['isConsistent']}
                    />
                    : null
            }
            historyData={historyPlateData}
            onDeleteItem={onDeleteHistoryItem}
            getResultItem={getDialogHistoryItem}
            // @ts-ignore
            onSaveButtonClick={() => saveReconciliationKpResult(resultData)}
        />
    )
}

export {Reconciliation};
export type {ReconciliationResult};

