import {isNumeric, maxIndexToBinary, numberToBinary} from "./numberProcessing";
import {EstimatesType, KpType} from "../components/common/entering/MetaKPDataEntering";
import {getEstimatesLength} from "../components/common/entering/KPEntering";

const str2numberEstimates = (estimates: any[], kpType: KpType) => {
    if (isNumeric(estimates[0])) {
        const first_estimates = kpType === 'conjuncts' ? 1 : 0
        return kpType === 'quants' ? [...estimates.map(e => Number(e))] : [first_estimates, ...estimates.map(e => Number(e))]
    } else {
        const first_estimates = kpType === 'conjuncts' ? [1, 1] : [0, 0]
        return kpType === 'quants' ? [...estimates.map(e => e.map((es: any) => Number(es)))] : [first_estimates, ...estimates.map(e => e.map((es: any) => Number(es)))]
    }
}

const checkAndGetEstimates = (
    estimates: string[] | string[][] | undefined,
    kpType: KpType,
    maxIndex: number[],
    estimatesType: EstimatesType
) => {
    const expectedLength = getEstimatesLength(kpType, maxIndex)
    const getOnlyErrorMessage = (errorMessage: string) => ({estimates: undefined, errorMessage})
    for (let i = 0; i < expectedLength; i++) {
        if (estimates === undefined || !estimates[i]) {
            // return getOnlyErrorMessage('Please, enter all estimates')
            // Translate
            return getOnlyErrorMessage('Пожалуйста, введите все оценки')
        }
        if (estimatesType === 'interval') {
            if (!isNumeric(estimates[i][0]) || !isNumeric(estimates[i][1])) {
                if (!estimates[i][0] || !estimates[i][1]) {
                    // Translate
                    // return getOnlyErrorMessage('Please, enter all estimates')
                    return getOnlyErrorMessage('Пожалуйста, введите все оценки')
                }
                // Translate
                // return getOnlyErrorMessage('Not a number was entered')
                return getOnlyErrorMessage('Введено не число')
            }
        } else {
            // @ts-ignore
            if (!isNumeric(estimates[i])) {
                // Translate
                // return getOnlyErrorMessage('Not a number was entered')
                return getOnlyErrorMessage('Введено не число')
            }
        }
    }
    return {
        errorMessage: '',
        // @ts-ignore
        estimates: str2numberEstimates(estimates, kpType)
    }
}

function subIdealIndexToIdeal(subIdealIndex: number, maxIdealIndex: number[]) {
    if (subIdealIndex === 0 && maxIdealIndex.length === 0) {
        return "0"
    }

    let idealIndex = ""
    const binarySubIdealIndex = numberToBinary(subIdealIndex)
    const binaryMaxIdealIndex = maxIndexToBinary(maxIdealIndex)
    let currentIdealBit = binaryMaxIdealIndex.length - 1
    for (let i = binarySubIdealIndex.length - 1; i >= 0; i--) {
        while (binaryMaxIdealIndex[currentIdealBit] === "0") {
            currentIdealBit--
            idealIndex = `0${idealIndex}`
        }
        currentIdealBit--
        idealIndex = `${binarySubIdealIndex[i]}${idealIndex}`
    }
    return idealIndex
}

export {str2numberEstimates, checkAndGetEstimates, subIdealIndexToIdeal}
