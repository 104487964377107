import React from 'react';
import Page from "../Page";
import 'katex/dist/katex.min.css';
import { KPTraining } from "./KPTraining/KPTraining";

const KpPage = () => {
    return (
        // <Page title="Training" tabData={[
        //     {
        //         label: 'Knowledge pattern',
        //         component: <KPTraining />
        //     }
        // ]} />
        <Page title="Обучение" tabData={[
            {
                label: 'Модель фрагмента знаний',
                component: <KPTraining />
            }
        ]} />
    );
};

export default KpPage;
