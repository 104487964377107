import React from 'react';
import '../App.css';
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import KpPage from "./pages/KPPage/KPPage";
import ParentSeparatorsGraphPage from "./pages/ParentSeparatorsGraphPage/ParentSeparatorsGraphPage";
import Header from "./Header";
import PrimaryStructure from "./pages/PrimaryStructure/PrimaryStructure";
import { paths } from "../config";
import NotFoundPage from "./pages/NotFoundPage";
import TrainingPage from "./pages/TrainingPage/TrainingPage";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: '#eeeeee',
            light: '#e35183',
            main: '#ad1457',
            dark: '#78002e'
        },
        secondary: {
            main: '#fff'
        }
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div>
                <Header />
                <Routes>
                    <Route path="*" element={<NotFoundPage />} />
                    <Route path={paths.main} element={<KpPage />} />
                    <Route path={paths.parentSeparatorsGraph} element={<ParentSeparatorsGraphPage />} />
                    <Route path={paths.primaryStructure} element={<PrimaryStructure />} />
                    <Route path={paths.training_representation} element={<TrainingPage />} />
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
